import LoadingSpinner from 'Components/LoadingSpinner/LoadingSpinner';
import { Recipe } from 'Features/Recipe/types';
import SearchResult from 'Features/Search/Components/SearchResult';
import { useEffect, useState } from 'react';
import { SearchType } from '../types';
/**
 * Get search result from store
 * @returns {React.ReactElement} - search result component
 */
export default function useSearchResult(
  type: SearchType,
  loading: boolean,
  result?: unknown,
): React.ReactElement {
  const [component, setComponent] = useState(<LoadingSpinner />);

  useEffect(() => {
    if (result && !loading) {
      switch (type) {
        case 'recipes':
          setComponent(<SearchResult result={result as Recipe[]} />);
          break;
        default:
          break;
      }
    }
  }, [result, loading, type]);
  return component;
}
