import LoadingSpinner from 'Components/LoadingSpinner/LoadingSpinner';
import ShortInfo from 'Components/ShortInfo/ShortInfo';
import { Recipe } from 'Features/Recipe/types';
import { Card } from 'grommet';
import useBlobImage from 'Hooks/useBlobImage';
import useFormattedTime from 'Hooks/useFormattedTime';
import useGetRandomBackgroundImage from 'Hooks/useGetRandomBackgroundImage';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  recipe: Recipe;
  row?: boolean;
};

const StyledImage = styled.div<{ row: boolean }>`
  &&& {
    margin-top: 0;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    width: ${({ row }) => (row ? '135px' : '100%')};
    height: ${({ row }) => (row ? '100px' : '100%')};
    display: flex;
    justify-content: ${({ row }) => (row ? 'flex-start' : 'center')};
    align-items: center;
  }
`;
const StyledCard = styled(Card)<{ $row?: boolean }>`
  position: relative;
  ${({ $row }) =>
    $row
      ? `
    height: 100px;
    width: 100%;
    margin: 0;
    flex-direction: row;
    `
      : `
    flex-direction: column;
    `}
  justify-content: flex-end;
  width: 100%;
  img {
    position: absolute;
    width: auto;
    height: ${({ $row }) => ($row ? '100px' : '100%')};
    margin-left: auto;
    margin-right: auto;
    top: ${({ $row }) => ($row ? 'unset' : '0')};
    left: ${({ $row }) => ($row ? '0' : 'unset')};
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: -webkit-transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
  }

  &:hover,
  &:focus {
    img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
`;
const StyledText = styled.div<{ row: boolean }>`
  backdrop-filter: blur(2.5px);
  background: rgba(255, 255, 255, 0.44);
  min-height: 6em;
  margin-bottom: 0em;
  margin-left: ${({ row }) => (row ? '15px' : '0')};
  flex-direction: column;
  position: ${({ row }) => (row ? 'relative' : 'absolute')};
  display: flex;
  justify-content: space-between;
  padding: ${({ row }) => (row ? '1em 0em' : '0 0.5em')};
  h3 {
    padding: 0;
    margin-block-end: 0.5em;
  }
  p {
    margin-bottom: 0.5em;
    padding: 0;
    font-size: 1.2em;
    line-height: 1.2em;
  }
  width: 100%;
  overflow: hidden;
`;

const RecipeCard: React.FC<Props> = ({ recipe, row = false }) => {
  const { name, cookingTime, id, portions } = recipe;
  const time = useFormattedTime(cookingTime);
  const defaultImage = useGetRandomBackgroundImage();
  const { blob, loading } = useBlobImage(id);
  const navigate = useNavigate();
  return (
    <StyledCard
      onClick={() => navigate(`/recipes/${id}`)}
      a11yTitle={`Link to ${name}`}
      elevation='medium'
      $row={row}
    >
      <StyledImage row={row}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <img
            loading='lazy'
            title={`${name}`}
            alt={`${name} recept bild. `}
            src={(blob && URL.createObjectURL(blob)) || defaultImage}
          />
        )}
      </StyledImage>
      <StyledText row={row}>
        <h3>{name}</h3>
        <ShortInfo time={time} portions={portions} />
      </StyledText>
    </StyledCard>
  );
};

export default RecipeCard;
