import useSearchResult from 'Features/Search/Hooks/useSearchResult';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import useSearch from '../Hooks/useSearch';
import { SearchType } from '../types';
import Search from './Search';

const StyledPage = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-self: flex-start;
`;
const StyledMain = styled.main`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const SearchPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { type, limit, query } = Object.fromEntries(searchParams);

  const { onSearch, isLoading, data } = useSearch(
    type as SearchType,
    parseInt(limit, 10),
  );
  const component = useSearchResult(type as SearchType, isLoading, data);
  useEffect(() => {
    onSearch(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, limit, query]);
  return (
    <StyledPage>
      <Search results={+limit} type='recipes' />
      {/* <TextInput suggestions={suggestions} /> */}
      <StyledMain>{component}</StyledMain>
    </StyledPage>
  );
};
export default SearchPage;
