const StaticFeatures = [
  {
    header: 'Personalized Meal Plans',
    body: 'Create custom meal plans based on your dietary preferences and schedule.',
    link: 'menus',
  },
  {
    header: 'Recipe Search Engine',
    body: 'Search thousands of recipes by ingredient, cuisine, or dietary need.',
    link: 'recipes',
  },
  {
    header: 'Shopping List Generator',
    body: 'Automatically generate a shopping list based on your menu and recipes.',
    link: 'menu',
  },
];
// eslint-disable-next-line import/prefer-default-export
export { StaticFeatures };
