import MainContainer from 'Components/Layout/MainContainer';
import Inspiration from 'Features/Recipe/Components/Inspiration/Inspiration';
import * as React from 'react';
import ContentSection from '../../Components/Layout/ContentSection';

const InspirationPage: React.FC = () => (
  <MainContainer>
    <ContentSection>
      <Inspiration />
    </ContentSection>
  </MainContainer>
);
export default InspirationPage;
