import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import groceryApi from 'Features/Grocery/GroceryApi';

export interface AppState {
  loading: boolean;
  units?: Record<number, string>;
}
const initialState: AppState = {
  loading: false,
};

export const getUnits = createAsyncThunk('app/units', async () => {
  const result = await groceryApi.units();
  return result;
});
const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setUnits: (state, action: PayloadAction<Record<number, string>>) => {
      state.units = action.payload;
      state.loading = false;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      state.loading = false;
      state.units = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnits.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUnits.fulfilled, (state, action) => {
      state.units = action.payload;
      state.loading = false;
    });
    builder.addCase(getUnits.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const { setLoading, reset } = appSlice.actions;
export default appSlice.reducer;
