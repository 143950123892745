import { useEffect, useState } from 'react';
import { Crop } from 'react-image-crop';
import * as exif from '@ginpei/exif-orientation';

type State = {
  src?: string;
  title?: string;
  image?: HTMLImageElement;
  rotation: number;
  zoom: number;
  crop: Crop;
  output?: string;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCrop = (
  save: (image: string, title?: string) => void,
  file?: File,
) => {
  const initialCrop: Crop = {
    height: 0,
    unit: 'px',
    width: 0,
    aspect: 4 / 3,
    x: 0,
    y: 0,
  };
  const [state, setState] = useState<State>({
    crop: initialCrop,
    zoom: 0,
    rotation: 0,
  });
  useEffect(() => {
    const fixOrientation = async (fileToFix?: File) => {
      // We can only fix orientation for jpgs
      if (!fileToFix || fileToFix.type !== 'image/jpeg') {
        return;
      }
      await exif.updateOrientationCode(
        fileToFix,
        exif.OrientationCode.deg90Flipped,
      );
    };
    fixOrientation(file).then(() =>
      setState((prevState) => ({
        ...prevState,
        src: file && URL.createObjectURL(file),
        title: file?.name,
      })),
    );
  }, [file]);

  const cropImage = () => {
    const { image, crop } = state ?? {};
    if (!image || !crop) {
      return;
    }

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    // Converting to base64
    const output = canvas.toDataURL('image/jpeg');
    setState((prevState) => ({
      ...prevState,
      output,
    }));

    // eslint-disable-next-line consistent-return
    return output;
  };

  const onChange = (crop: Crop) =>
    setState((prevState) => ({
      ...prevState,
      crop,
    }));

  const reset = () =>
    setState((prevState) => ({
      ...prevState,
      crop: initialCrop,
    }));

  const onImageLoaded = (image: HTMLImageElement) =>
    setState((prevState) => ({
      ...prevState,
      image,
    }));

  const onSave = async () => {
    const { crop, src } = state || {};

    if (crop === initialCrop && src) {
      save(src?.replace('data:', '').replace(/^.+,/, ''));
      return;
    }
    const cropped = cropImage();
    if (cropped) {
      save(cropped?.replace('data:', '').replace(/^.+,/, ''));
    }
  };
  const zoomOut = () =>
    setState((prevState) => ({
      ...prevState,
      zoom: prevState.zoom > 1 ? prevState.zoom - 0.1 : prevState.zoom,
    }));
  const zoomIn = () =>
    setState((prevState) => ({
      ...prevState,
      zoom: prevState.zoom > 1 ? prevState.zoom - 0.1 : prevState.zoom,
    }));
  const rotate = () =>
    setState((prevState) => ({
      ...prevState,
      rotation: prevState.rotation === 360 ? 0 : prevState.rotation + 90,
    }));
  const { src, crop, output, zoom, rotation } = state || {};
  return {
    src,
    crop,
    rotation,
    zoom,
    output,
    onChange,
    reset,
    cropImage,
    onImageLoaded,
    onSave,
    rotate,
    zoomIn,
    zoomOut,
  };
};
export default useCrop;
