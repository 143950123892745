import { Recipe } from 'Features/Recipe/types';
import { useEffect, useState } from 'react';
import recipeApi from '../Features/Recipe/RecipeApi';

const useGetPopularRecipes = (limit: number): Recipe[] | undefined => {
  const [result, setResult] = useState<Recipe[]>();

  useEffect(() => {
    async function getResult() {
      const data = await recipeApi.popular(limit);
      if (data) {
        setResult(data);
      }
    }
    getResult();
  }, [limit]);

  return result;
};
export default useGetPopularRecipes;
