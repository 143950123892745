import { ApiMessage } from 'Api/Interfaces/ApiMessage';
import { Dispatch } from 'redux';
import { GetMessageFromStatusCode } from 'Utils/Utils';

import { clearMessage, setMessage } from './messageSlice';

export const set =
  (apiMessage: ApiMessage) =>
  (dispatch: Dispatch): void => {
    const { genitive, type, statusCode } = apiMessage;
    const message = GetMessageFromStatusCode(statusCode, genitive, type);
    dispatch(setMessage({ message, autoClose: true }));
  };
export const reset =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(clearMessage());
  };
