import { Cafeteria, Clock } from 'grommet-icons';
import styled from 'styled-components';

const StyledClock = styled(Clock)`
  font-size: 0.8em;
  margin-right: 0.3em;
`;

const StyledFork = styled(Cafeteria)`
  font-size: 0.8em;
  margin-right: 0.3em;
`;

const StyledContent = styled.div<{ size?: string }>`
  &&& {
    font-size: ${({ size }) => size || '1'}rem;
    h2 {
      margin: 0%;
      font-size: 1.1em;
    }
    p {
      font-size: 0.9em;
    }
    span:first-of-type {
      margin-right: 1.5em;
    }
    font-weight: normal;
  }
`;
const ShortInfo: React.FC<{
  time: string;
  portions: number;
  size?: number;
}> = ({ time, portions, size }) => (
  <StyledContent size={size?.toString()}>
    <span>
      <StyledClock />
      {time}
    </span>
    <span>
      <StyledFork />
      {portions} portioner
    </span>
  </StyledContent>
);
export default ShortInfo;
