import * as React from 'react';
import styled from 'styled-components';

interface StylingProps {
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  width?: string;
}
const StyledContent = styled.section<StylingProps>`
  padding: ${({ padding, theme }) => padding || theme.padding.defaultPadding};
  width: ${({ width, theme }) => width || theme.contentWidth};
  max-width: 100%;
  position: relative;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.contentBackgroundColor};
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 100%;
  }
`;

const ContentSection: React.FC = ({ children }) => (
  <StyledContent>{children}</StyledContent>
);
export default ContentSection;
