import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Suggestable } from 'Features/Search/types';
import groceryApi from './GroceryApi';
import { Grocery } from './Interfaces';

export interface GroceryState {
  loading: boolean;
  groceries?: Grocery[];
  currentGrocery?: Grocery;
  suggestions?: Suggestable[];
  totalCount: number;
}
const initialState: GroceryState = {
  loading: false,
  totalCount: 0,
};

export const getGrocery = createAsyncThunk(
  'grocery/byId',
  async (id: string) => {
    const result = await groceryApi.byId(id);
    return result;
  },
);
export const getPaginated = createAsyncThunk(
  'grocery/paginated',
  async (pagination: [number, number]) => {
    const result = await groceryApi.paginated(pagination[0], pagination[1]);
    return result;
  },
);
export const deleteGrocery = createAsyncThunk(
  'grocery/delete',
  async (id: string) => {
    const result = await groceryApi.remove(id);
    return result;
  },
);
export const deleteGroceries = createAsyncThunk(
  'grocery/deleteMany',
  async (ids: string[]) => {
    const result = await groceryApi.removeMany(ids);
    return result;
  },
);
const grocerySlice = createSlice({
  initialState,
  name: 'grocery',
  reducers: {
    setGroceries: (state, action: PayloadAction<Grocery[]>) => {
      state.groceries = action.payload;
      state.loading = false;
    },
    setCurrentGrocery: (state, action: PayloadAction<Grocery>) => {
      state.currentGrocery = action.payload;
      state.loading = false;
    },
    setSuggestions: (state, action: PayloadAction<Suggestable[]>) => {
      state.suggestions = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      state.loading = false;
      state.groceries = undefined;
      state.currentGrocery = undefined;
      state.suggestions = undefined;
      state.totalCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGrocery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGrocery.fulfilled, (state, action) => {
      state.currentGrocery = action.payload;
      state.loading = false;
    });
    builder.addCase(getGrocery.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPaginated.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaginated.fulfilled, (state, action) => {
      state.groceries = action.payload.result;
      state.totalCount = action.payload.totalCount;
      state.loading = false;
    });
    builder.addCase(getPaginated.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGrocery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteGrocery.fulfilled, (state) => {
      state.groceries = undefined;
      state.totalCount = 0;
      state.loading = false;
    });
    builder.addCase(deleteGrocery.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const {
  setGroceries,
  setCurrentGrocery,
  setSuggestions,
  setLoading,
  reset,
} = grocerySlice.actions;
export default grocerySlice.reducer;
