import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ExtendedMenu } from '../../../Types/types';

type Props = {
  menu: ExtendedMenu;
  row?: boolean;
};
const StyledGridLink = styled(Link)<{ row?: boolean }>`
  ${({ row }) =>
    row
      ? `
  height: 100px;
  width: 100%;
  margin: 0;
  flex-direction: row;
  `
      : `
  flex-direction: column;
  `}
  display: flex;
	max-width: 100%;
  justify-content: flex-start;
	background-color: ${({ theme }) => theme.colors.backgroundColor};
	overflow: hidden;
	cursor: pointer;
	box-shadow: 0px 0px 5px 0px ${({ theme }) =>
    theme.colors.allowedColors.warmblacktransparent};
}`;

const StyledText = styled.div<{ row?: boolean }>`
  margin-top: 0em;
  margin-left: ${({ row }) => (row ? '15px' : '0')};
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding: ${({ row }) => (row ? '1em 0em' : '0 0.5em')};
  h3 {
    padding: 0;
    margin-block-end: 0.5em;
  }
  p {
    padding: 0;
    font-size: 1.2em;
    line-height: 1.2em;
  }
  width: 100%;
  background: rgba(255, 255, 255, 0.65);
  overflow: hidden;
`;

const MenuListItem: React.FC<Props> = ({ menu, row }) => {
  const { name, description, id } = menu;
  return (
    <StyledGridLink
      row={row}
      className={`menu-list--item ${
        row ? 'menu-list--item__row' : 'menu-list--item__grid'
      }`}
      to={`vecko-menyer/${id}`}
    >
      <StyledText row={row}>
        <h3>{name}</h3>
        <p>{description}</p>
      </StyledText>
    </StyledGridLink>
  );
};
export default MenuListItem;
