import { Recipe } from 'Features/Recipe/types';
import { useEffect, useState } from 'react';
import { User } from '../User/Interfaces/User';

export const useIsEditingAllowed = (user?: User, recipe?: Recipe): boolean => {
  const [allowed, setAllowed] = useState<boolean>(false);
  useEffect(() => {
    if (user && recipe && recipe.creator) {
      setAllowed(user.id === recipe.creator);
    }
  }, [user, recipe]);
  return allowed;
};
export default useIsEditingAllowed;
