/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'Hooks/storeHooks';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { reset } from 'Features/Grocery/grocerySlice';
import { useNavigate } from 'react-router-dom';
import useOutsideAlerter from 'Hooks/useOutsideAlerter';
import useBlobImage from 'Hooks/useBlobImage';
import ImageInput from 'Features/Forms/ImageInput/ImageInput';
import { Button, TextArea, TextInput } from 'grommet';
import {
  Ingredient,
  UploadedImage,
  CreatedRecipe,
  Recipe,
} from 'Features/Recipe/types';
import Instructions from '../../../Forms/Instructions/Instructions';
import { NutritionValue } from '../../../Grocery/Interfaces';

import recipeApi from '../../RecipeApi';
import { FormType } from '../../../../Types/Enums';
import FormPart from '../../../Forms/FormPart';
import ProgressBar from '../../../Forms/ProgressBar/ProgressBar';
import Groceries from './Groceries';

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const StyledButtonContainer = styled.div`
  justify-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const StyledTextArea = styled(TextArea)`
  min-height: 10em;
`;
interface State extends InputState {
  recipe: Recipe | null;
  showAddInstructions: boolean;
  redirectUrl?: string;
  step: number;
  formHeight?: number;
}

interface InputState {
  name: string;
  description: string;
  portions: number;
  ingredients: Ingredient[];
  image?: UploadedImage;
  instructions: string[];
  instruction: string;
  cookingTime: number;
  previewImage?: string;
}
interface Props {
  formType: FormType;
}
const initialState: State = {
  name: '',
  description: '',
  portions: 4,
  recipe: null,
  ingredients: [] as Ingredient[],
  instructions: [] as string[],
  instruction: '',
  cookingTime: 30,
  showAddInstructions: false,
  redirectUrl: undefined,
  step: 1,
};
const RecipeForm: React.FC<Props> = ({ formType }) => {
  const [inputState, setInputState] = useState<State>(initialState);
  const { currentUser } = useAppSelector((state) => state.user);
  const { currentRecipe } = useAppSelector((state) => state.recipe);
  const { currentGrocery } = useAppSelector((state) => state.grocery);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blob } = useBlobImage(currentRecipe?.id);
  const loginRef = useRef(null);
  useOutsideAlerter(loginRef);
  const formRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (formType === FormType.UPDATE && currentRecipe) {
      setInputState({
        ...inputState,
        name: currentRecipe.name,
        description: currentRecipe.description,
        portions: currentRecipe.portions,
        ingredients: currentRecipe.ingredients,
        instructions: currentRecipe.instructions,
        cookingTime: currentRecipe.cookingTime,
        previewImage: blob && URL.createObjectURL(blob),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const key = event.currentTarget.name as keyof InputState;
    const value = event.currentTarget.value as string;
    setInputState((prevState) => ({
      ...prevState,
      [key]: value as unknown as Pick<InputState, keyof InputState>,
    }));
  };

  const saveImage = (image: string, title?: string) => {
    setInputState((prevState) => ({
      ...prevState,
      image: {
        image,
        title,
      },
    }));
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      return;
    }
    const {
      name,
      portions,
      description,
      ingredients,
      instructions,
      cookingTime,
      image,
    } = inputState || {};
    if (!currentUser) {
      return;
    }

    const { id } = currentUser || {};
    const newRecipe: CreatedRecipe = {
      name,
      portions: +portions,
      description,
      cookingTime: +cookingTime,
      instructions,
      ingredients,
      image,
      nutritions: [] as NutritionValue[],
      creator: id,
    };

    if (formType === FormType.UPDATE) {
      const updatedRecipe: Recipe = {
        id: currentRecipe?.id,
        ...newRecipe,
      };
      await recipeApi.update(updatedRecipe);
      return;
    }
    const recipeId = await recipeApi.create(newRecipe);

    navigate(`/recipes/${recipeId}`);
  };

  const setIngredients = (ingredients: Ingredient[]) => {
    setInputState((prevState) => ({
      ...prevState,
      ingredients,
    }));
  };

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const setInstructions = (instructions: string[]) => {
    setInputState((prevState) => ({
      ...prevState,
      instructions,
    }));
  };

  const incrementStep = () => {
    // if (!formRef.current?.checkValidity()) {
    //   return;
    // }
    setInputState((prevState) => ({
      ...prevState,
      step: prevState.step >= 3 ? prevState.step : prevState.step + 1,
    }));
  };

  const decrementStep = () => {
    setInputState((prevState) => ({
      ...prevState,
      step: prevState.step <= 1 ? prevState.step : prevState.step - 1,
    }));
  };

  useEffect(() => {
    if (currentGrocery) {
      const ingredient: Ingredient = {
        grocery: currentGrocery,
        name: currentGrocery.name,
        quantity: 1,
        weight: 0,
        unit: 1,
      };
      setInputState((prevState) => ({
        ...prevState,
        ingredients: [...prevState.ingredients, ingredient],
      }));
      dispatch(reset());
    }
  }, [currentGrocery, dispatch]);
  const { name, portions, ingredients, instructions, cookingTime, step } =
    inputState || {};

  return (
    <div role='presentation' onKeyDown={onKeyDown}>
      <StyledForm
        ref={formRef}
        id='recipe-form'
        onSubmit={onSubmit}
        encType='multipart/form-data'
        className='recipe-form'
      >
        <ProgressBar max={3} current={step} />
        {step === 1 && (
          <FormPart part={1}>
            <ImageInput save={saveImage} />
            <label htmlFor='name'>
              Name:
              <TextInput
                required
                name='name'
                onChange={onChange}
                value={name}
              />
            </label>
            <label>
              Description:
              <StyledTextArea
                minLength={50}
                resize={false}
                name='description'
                onChange={onChange}
                placeholder='Short description...'
                maxLength={150}
                required
                rows={1}
                cols={3}
              />
            </label>
            <label htmlFor='cookingTime'>
              Cooking time (in minutes):
              <TextInput
                name='cookingTime'
                type='number'
                onChange={onChange}
                value={cookingTime}
                required
                min={1}
              />
            </label>
            <label htmlFor='portions'>
              Number of servings:
              <TextInput
                name='portions'
                type='number'
                onChange={onChange}
                value={portions}
                required
                min={1}
              />
            </label>
          </FormPart>
        )}
        {step === 2 && (
          <FormPart part={2} title='Ingredienser'>
            <Groceries
              ingredients={ingredients}
              setIngredients={setIngredients}
            />
          </FormPart>
        )}
        {step === 3 && (
          <FormPart part={3} title='Instruktioner'>
            <Instructions
              setInstructions={setInstructions}
              instructions={instructions}
            />
          </FormPart>
        )}
        <StyledButtonContainer>
          {step >= 2 && (
            <Button
              size='medium'
              type='button'
              label='Back'
              onClick={decrementStep}
            />
          )}
          {step < 3 && (
            <Button
              alignSelf={step === 1 ? 'end' : 'start'}
              primary
              size='medium'
              type='button'
              onClick={incrementStep}
              label='Next'
            />
          )}
          {step === 3 && (
            <Button
              primary
              disabled={instructions.length <= 0}
              type='submit'
              label={`${formType === FormType.CREATE ? 'Create' : 'Update'}`}
            />
          )}
        </StyledButtonContainer>
      </StyledForm>
    </div>
  );
};

export default RecipeForm;
