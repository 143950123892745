/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from '@reduxjs/toolkit';
import { setMessage } from 'Features/Messages/messageSlice';
import recipeApi from './RecipeApi';
import { setCurrentRecipe, setLoading, setRecipes } from './recipeSlice';
import { Recipe } from './types';

export const getRecipe = (id: string) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  recipeApi
    .byId(id)
    .then((recipe: Recipe) => {
      dispatch(setCurrentRecipe(recipe));
    })
    .catch((error: Error) => {
      dispatch(setMessage({ message: error.message, autoClose: true }));
      dispatch(setLoading(false));
      throw error;
    });
};
export const getRecentRecipes = (limit: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  recipeApi
    .recent(limit)
    .then((recipes: Recipe[]) => {
      dispatch(setRecipes(recipes));
    })
    .catch((error: Error) => {
      dispatch(setMessage({ message: error.message, autoClose: true }));
      dispatch(setLoading(false));
      throw error;
    });
};
export const getPopularRecipes = (limit: number) => (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  recipeApi
    .popular(limit)
    .then((recipes: Recipe[]) => {
      dispatch(setRecipes(recipes));
    })
    .catch((error: Error) => {
      dispatch(setMessage({ message: error.message, autoClose: true }));
      dispatch(setLoading(false));
      throw error;
    });
};
export const searchRecipes =
  (query: string, start: number, limit: number) => (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    recipeApi
      .search(query, start, limit, new AbortController())
      .then(({ item1 }) => {
        if (item1) {
          dispatch(setRecipes(item1));
        }
      })
      .catch((error: Error) => {
        dispatch(setMessage({ message: error.message, autoClose: true }));
        dispatch(setLoading(false));
        throw error;
      });
  };
