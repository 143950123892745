/* eslint-disable jsx-a11y/label-has-associated-control */
import { ApiConfig } from 'Api/ApiConfig';
import MenuApi from 'Api/MenuApi';
import TextAreaInput from 'Features/Forms/TextAreaInput/TextAreaInput';
import { Recipe } from 'Features/Recipe/types';
import { Button, TextInput } from 'grommet';
import * as React from 'react';
import { useState } from 'react';

interface State {
  name: string;
  description: string;
}
interface Props {
  recipesWidthDays?: Record<string, Recipe>;
}
const initialState = {
  name: '',
  description: '',
};

const CreateMenu: React.FC<Props> = ({ recipesWidthDays }) => {
  const [state, setState] = useState<State>(initialState);
  const api = new MenuApi(ApiConfig);

  const saveMenu = async () => {
    if (recipesWidthDays) {
      const menu = {
        name: state.name,
        description: state.description,
        recipesWidthDays,
      };
      await api.saveMenu(menu);
    }
  };
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const key = event.currentTarget.name as keyof State;
    const value = event.currentTarget.value as string;
    setState({
      ...state,
      [key]: value as unknown as Pick<State, keyof State>,
    });
  };
  const { name, description } = state;
  return (
    <section>
      <label htmlFor='name'>
        Name:
        <TextInput
          name='name'
          value={name}
          onChange={onChange}
          placeholder='Name'
          type='text'
        />
      </label>
      <label htmlFor='description'>
        Description:
        <TextAreaInput
          name='description'
          externalValue={description}
          handleChange={onChange}
          label='Description: '
          placeholder='Description'
        />
        <Button primary type='button' onClick={saveMenu} label='Save' />
      </label>
    </section>
  );
};
export default CreateMenu;
