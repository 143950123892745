import * as React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../../Hooks/useWindowSize';
import Menu from '../Menu/Menu';
import Hamburger from '../HamburgerMenu/HamburgerMenu';

const StyledDiv = styled.div`
  padding: 0px 20px;
  margin: 0;

  @media screen and (max-width: $medium-breakpoint) {
    padding: 0px;
  }
`;
type Props = {
  breakpoint: number;
};
const MenuContainer: React.FC<Props> = (props) => {
  const dimensions = useWindowSize();
  const { breakpoint } = props;
  return (
    <StyledDiv>
      {dimensions && dimensions.width > breakpoint ? <Menu /> : <Hamburger />}
    </StyledDiv>
  );
};
export default MenuContainer;
