import { Button, Text } from 'grommet';
import { AddCircle } from 'grommet-icons';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import InstructionsList from './InstructionsList';

const StyledSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  .form-instructions--toggle-button {
  }
  .form-instructions--toggle-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      margin-right: 10px;
    }
  }
`;
const StyledToggle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    margin-right: 10px;
  }
`;
const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

interface Props {
  instructions: string[];
  setInstructions: (instructions: string[]) => void;
}
const Instructions: React.FC<Props> = ({ instructions, setInstructions }) => {
  const [showInstructionsField, setShowInstructionsField] =
    useState<boolean>(true);
  const toggleField = async () => {
    setShowInstructionsField(!showInstructionsField);
  };
  const removeInstruction = async (index: number) => {
    setInstructions(instructions.filter((_, i) => i !== index));
  };
  const addInstruction = async (instruction: string) => {
    if (instruction.length > 0) {
      setInstructions([...instructions, instruction]);
    }
  };
  const updateInstruction = async (index: number, instruction: string) => {
    instructions.splice(index, 1, instruction);
    setInstructions(instructions);
  };
  return (
    <StyledSection>
      {instructions ? (
        <InstructionsList
          setInstructions={setInstructions}
          instructions={instructions}
          removeInstruction={removeInstruction}
          updateInstruction={updateInstruction}
        />
      ) : (
        <Text> There is no instructions yet </Text>
      )}

      <StyledToggle>
        {showInstructionsField ? (
          <AddCircle onClick={toggleField} />
        ) : (
          <StyledButton
            type='button'
            secondary
            onClick={toggleField}
            label='Add instruction'
          />
        )}
      </StyledToggle>
      {showInstructionsField && (
        <TextAreaInput
          label='Add instruction '
          name='instructions'
          useButton
          onButtonClick={addInstruction}
          buttonText='Add'
        />
      )}
    </StyledSection>
  );
};
export default Instructions;
