import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Api from '../Api/Api';
import { ApiConfig } from '../Api/ApiConfig';
import { ApiResponse } from '../Api/Interfaces/ApiResponse';
import { NewUser } from './Interfaces/NewUser';
import { User } from './Interfaces/User';

enum Endpoints {
  CREATE = '/create',
  UPDATE = '/update',
  DELETE = '/delete',
  CURRENT = '/current',
}
export class UserApi extends Api {
  private baseUrl;

  constructor(config: AxiosRequestConfig) {
    super(config);
    this.baseUrl = `/users`;
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.current = this.current.bind(this);
  }

  /**
   * Adds a new user into our system.
   *
   * @param {NewUser} newUser - the new user to add.
   * @returns {Promise<User>} the user created`.
   */
  public create(newUser: NewUser): Promise<User> {
    return this.post<ApiResponse<User>, NewUser>(
      `${this.baseUrl}${Endpoints.CREATE}`,
      newUser,
    )
      .then((response) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Updates the user passed in
   *
   * @param {NewUser} newUser - the user to update.
   * @returns {Promise<User>} - the user updated.
   */
  public update(newUser: User): Promise<User> {
    return this.put<ApiResponse<User>, User>(
      `${this.baseUrl}/${newUser.id}`,
      newUser,
    )
      .then((response) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Deletes the user associated with the given id.
   *
   * @param {string} id - the id of the user to delete.
   * @returns {Promise<unknown>} the request promise.
   */
  public remove(id: string): Promise<AxiosResponse> {
    return this.delete(`${id}`);
  }

  /**
   * Gets the current user from active token.
   *
   * @returns {Promise<User>} - the current user.
   */
  public current(): Promise<User | undefined> {
    return this.get<ApiResponse<User>>(`${this.baseUrl}${Endpoints.CURRENT}`)
      .then((response) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch(
        () =>
          // Do nothing
          undefined,
      );
  }
}
const userApi = new UserApi(ApiConfig);
export default userApi;
