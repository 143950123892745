import styled from 'styled-components';

const StyledNav = styled.li`
  list-style: none;
  cursor: pointer;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  svg {
    margin-right: 1em;
  }
  &::after {
    content: '';
    position: absolute;
    height: 0.1em;
    left: 0.9em;
    right: 0.9em;
    bottom: -0.5em;
    background: currentColor;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: -webkit-transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
  }
  &:hover,
  &:focus {
    &::after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
`;
const Navigation = ({
  label,
  icon,
  onClick,
}: {
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
}): JSX.Element => (
  <StyledNav onClick={onClick}>
    {icon}
    {label}
  </StyledNav>
);
export default Navigation;
