import { Box, Button, Heading } from 'grommet/components';
import * as React from 'react';
import styled from 'styled-components';

interface StyledProps {
  $image: string;
}
const StyledImage = styled.div<StyledProps>`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  background-image: ${({ $image }) => `url(${$image})`};
  align-items: center;
  justify-content: center;

  z-index: -1;
  position: absolute;
`;

const StyledImageContainer = styled.div`
  width: 100%;
  min-height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  position: relative;
`;

const StyledHeading = styled(Heading)`
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;
const StyledButton = styled(Button)`
  font-size: 1.6rem;
  margin-top: 1em;
`;
const StyledBox = styled(Box)`
  background: rgba(255, 255, 255, 0.34);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.3px);
`;
interface Props {
  image: string;
}
const Banner: React.FC<Props> = ({ image }) => (
  <StyledImageContainer>
    <StyledImage $image={image} />
    <StyledBox
      pad='3em'
      align='center'
      a11yTitle='hero content'
      alignContent='center'
    >
      <StyledHeading> Get Inspired in the Kitchen</StyledHeading>
      <StyledHeading level={2}>
        Discover new recipes, plan weekly menus, and create shopping lists with
        ease
      </StyledHeading>
      <StyledButton primary size='large' label='Start now' />
    </StyledBox>
  </StyledImageContainer>
);

export default Banner;
