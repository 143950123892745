import { reset } from 'Features/Recipe/recipeSlice';
import { Trash } from 'grommet-icons';
import { useAppSelector } from 'Hooks/storeHooks';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import recipeApi from '../../Features/Recipe/RecipeApi';
import { AppState } from '../../Store/store';
import { User } from '../../User/Interfaces/User';
import DeleteForm from '../ConfirmDelete/DeleteForm';
import Popup from '../Popup/Popup';

const StyledDelete = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const DeleteButton = (): JSX.Element => {
  const { currentRecipe } = useAppSelector((state) => state.recipe);
  const { id } = currentRecipe || {};
  const [open, setOpen] = useState<boolean>(false);
  const currentUser = useSelector<AppState>(
    (state) => state.user.currentUser,
  ) as User;
  const dispatch = useDispatch();
  const onClick = () => {
    setOpen(!open);
  };
  const onPopupClose = () => setOpen(false);

  const deleteRecipe = async () => {
    if (currentUser && currentUser.id && id) {
      await recipeApi.remove(id);
    }
  };
  const onAccept = async () => {
    setOpen(false);
    await deleteRecipe();
    dispatch(reset());
    onPopupClose();
  };
  return (
    <StyledDelete>
      <Trash size='28px' type='button' onKeyDown={onClick} onClick={onClick} />
      {open && (
        <Popup isOpen={open} onPopupClose={() => onPopupClose}>
          <DeleteForm type='Recipe' onAccept={onAccept} />
        </Popup>
      )}
    </StyledDelete>
  );
};
export default DeleteButton;
