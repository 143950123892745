import { useAppSelector } from 'Hooks/storeHooks';
import { useEffect, useState } from 'react';
import MessagePopup from './MessagePopup';

function useGetMessage(): JSX.Element | undefined {
  const [component, setComponent] = useState<JSX.Element | undefined>();
  const { message, autoClose } = useAppSelector((state) => state.messages);

  useEffect(() => {
    setComponent(<></>);
    if (message) {
      setComponent(<MessagePopup message={message} autoClose={autoClose} />);
    }
  }, [message, autoClose]);
  return component;
}
export default useGetMessage;
