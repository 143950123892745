import Button from 'Components/Button/Button';
import { useAppDispatch } from 'Hooks/storeHooks';
import styled from 'styled-components';

import { reset } from './MessageEffects';

interface Props {
  message: string;
  autoClose?: boolean;
}
const MessagePopup: React.FC<Props> = ({ message, autoClose }) => {
  const dispatch = useAppDispatch();
  const StyledMessage = styled.div`
    z-index: 10;
    background-color: #fff;
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    padding: 20px;
    width: 300px;
    min-height: 150px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @extend .default-box-shadow;
    .error-message {
      font-size: 1rem;
      text-align: center;
      margin-top: 5px;
    }
  `;
  const StyledText = styled.p`
    font-size: 1rem;
    text-align: center;
    margin-top: 5px;
  `;
  const onClick = () => dispatch(reset());
  if (autoClose) {
    setTimeout(() => dispatch(reset()), 3000);
  }
  return (
    <StyledMessage>
      <StyledText>{message}</StyledText>
      <Button type='button' onClick={onClick}>
        Stäng
      </Button>
    </StyledMessage>
  );
};
export default MessagePopup;
