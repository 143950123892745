import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ContentSection from '../Layout/ContentSection';

type Props = {
  link?: string;
  linkText: string;
};

const StyledError = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    line-height: 1.4em;
  }
  a {
    font-size: 2em;
  }
`;
const ErrorComponent: React.FC<Props> = ({ children, link, linkText }) => (
  <ContentSection>
    <StyledError>
      <h2>{children}</h2>
      <Link to={`${link}`}>{linkText}</Link>
    </StyledError>
  </ContentSection>
);
export default ErrorComponent;
