import React from 'react';
import { Header as GrommetHeader } from 'grommet/components';
import styled from 'styled-components';
import Search from 'Features/Search/Components/Search';
import Logo from '../Logo/Logo';
import MenuContainer from '../MenuContainer/MenuContainer';

const StyledHeader = styled(GrommetHeader)`
  min-height: 4em;
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.68);
`;

const Header: React.FC = () => (
  <StyledHeader background='brand' sticky='scrollup'>
    <Logo />
    <Search
      background='white'
      type='recipes'
      results={10}
      height={1}
      navigateToSearch
    />
    <MenuContainer breakpoint={992} />
  </StyledHeader>
);

export default Header;
