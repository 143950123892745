import { NutritionValue } from 'Features/Grocery/Interfaces';
import styled from 'styled-components';
import { defaultTheme } from 'theme';

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  list-style: none;
`;
const StyledItem = styled.li`
  margin: 0.25em 0.5em;
  padding: 0.4em 0.75em;
  border-radius: 15px;
  background-color: ${defaultTheme.global?.colors?.['accent-1']};
`;
const Nutritions: React.FC<{
  nutritions?: NutritionValue[];
  className?: string;
}> = ({ nutritions, className }) => (
  <>
    {nutritions && (
      <StyledList className={className}>
        {nutritions
          ?.filter(
            (nutrition) =>
              nutrition.nutrition.common ||
              nutrition.nutrition.extended ||
              nutrition.nutrition.vitamin,
          )
          .sort((a, b) => a.nutrition.name.localeCompare(b.nutrition.name))
          .map(({ nutrition: { name }, value, unit }) => (
            <StyledItem key={name}>
              {name} {value} {unit}
            </StyledItem>
          ))}
      </StyledList>
    )}
  </>
);
export default Nutritions;
