import * as React from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { TextInput as GrommetTextInput } from 'grommet';
import inputStyles from '../InputStyles';

const StyledInputContainer = styled.div<{ $noLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ $noLabel }) => ($noLabel ? '0' : '5px 0px')};

  &&& input {
    ${inputStyles}
    ${({ $noLabel }) => $noLabel && 'margin-top: 0;'}
  }
`;
const StyledInput = styled(GrommetTextInput)<Partial<Props>>``;
const StyledErrorText = styled.p`
  color: orangered;
  font-weight: 600;
  margin-top: 0.3em;
`;
interface Props {
  id?: string;
  name: string;
  type?: string;
  label?: string;
  autoComplete?: string;
  placeholder?: string;
  error?: FieldError;
  value?: string | number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
  min?: number | string;
  className?: string;
  step?: number | string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const TextInput = React.forwardRef<HTMLInputElement, Omit<Props, 'ref'>>(
  (
    {
      id,
      name,
      label,
      autoComplete,
      placeholder,
      error,
      onChange,
      onBlur,
      className,
      ...props
    },
    ref:
      | ((instance: HTMLInputElement | null) => void)
      | React.MutableRefObject<HTMLInputElement | null>
      | null,
  ) => (
    <StyledInputContainer
      $noLabel={label == null || label === undefined || label === ''}
      className={className}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <StyledInput
        id={id}
        name={name}
        key={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        aria-invalid={error ? 'true' : 'false'}
        placeholder={placeholder}
        autoComplete={autoComplete}
        {...props}
      />
      {error && <StyledErrorText role='alert'>{error.message}</StyledErrorText>}
    </StyledInputContainer>
  ),
);

export default TextInput;
