import { Grid, List } from 'grommet';
import * as React from 'react';
import { useState } from 'react';
import { ExtendedMenu } from '../../Types/types';
import MenuListItem from './MenuListItem/MenuListItem';

interface Props {
  title?: string;
  menus: Array<ExtendedMenu> | null;
  showToggle?: boolean;
  initialRow?: boolean;
}

const MenuList: React.FC<Props> = ({
  title,
  menus,
  showToggle,
  initialRow,
}) => {
  const [row, setRow] = useState<boolean>(!initialRow);
  const onClick = () => {
    if (showToggle) {
      setRow(!row);
    }
  };
  return (
    <section className='menu-list'>
      {title && <h2 className='menu-list--title'>{title}</h2>}
      {showToggle &&
        (row ? <Grid onClick={onClick} /> : <List onClick={onClick} />)}
      <div className='menu-list--inner'>
        {menus?.map((menu) => (
          <MenuListItem key={menu.id} menu={menu} row={row} />
        )) ?? <></>}
      </div>
    </section>
  );
};

export default MenuList;
