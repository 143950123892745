import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Api from '../Api/Api';
import { ApiConfig } from '../Api/ApiConfig';
import { ApiResponse } from '../Api/Interfaces/ApiResponse';
import { AuthenticateRequest } from './Interfaces/AuthenticateRequest';
import { JwtToken } from './Interfaces/JwtToken';

enum TokenApiEndpoints {
  AUTHENTICATE = '/authenticate',
  REVOKE = '/revoke',
  RENEW = '/renew',
}
export class TokenApi extends Api {
  private baseUrl;

  constructor(config: AxiosRequestConfig) {
    super(config);
    this.baseUrl = `/tokens`;
    this.authenticate = this.authenticate.bind(this);
    this.revoke = this.revoke.bind(this);
    this.renew = this.renew.bind(this);
  }

  /**
   * Generates an HTTP Request to login the the user.
   *
   * @param {AuthenticateRequest} authenticateRequest - user's identifications.
   * @returns {Promise<JwtToken>} - a valid JWT token.
   */

  public authenticate(
    authenticateRequest: AuthenticateRequest,
  ): Promise<JwtToken> {
    return this.post<ApiResponse<JwtToken>, AuthenticateRequest>(
      `${this.baseUrl}${TokenApiEndpoints.AUTHENTICATE}`,
      authenticateRequest,
      ApiConfig,
    )
      .then((response: AxiosResponse<ApiResponse<JwtToken>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to logut the the user.
   *
   * @param {string} id - id of the user to destroy tokens for
   * @returns {Promise<void>} - the request promise
   */

  public revoke(): Promise<unknown> {
    return this.get<JwtToken, AxiosResponse>(
      `${this.baseUrl}${TokenApiEndpoints.REVOKE}`,
    ).catch((error: AxiosError) => {
      throw error;
    });
  }

  /**
   * Renews current user token
   *
   * @returns {Promise<JwtToken>} - promise containing the new token
   */
  public renew(): Promise<JwtToken> {
    return this.get<ApiResponse<JwtToken>>(
      `${this.baseUrl}${TokenApiEndpoints.RENEW}`,
    )
      .then((response: AxiosResponse<ApiResponse<JwtToken>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const tokenApi = new TokenApi(ApiConfig);
export default tokenApi;
