/* eslint-disable */
import GroceryForm from 'Features/Grocery/Components/IngredientForm/GroceryForm';
import IngredientList from 'Features/Admin/MyIngredients/IngredientList/IngredientList';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Search from 'Features/Search/Components/Search';
import { Grocery } from '../../Grocery/Interfaces';
import Popup from '../../../Components/Popup/Popup';
import { Box, Main, PageContent, Pagination, Spinner } from 'grommet';
import useSearch from 'Features/Search/Hooks/useSearch';
import { SearchResult } from 'Features/Search/types';
const PAGE_LIMIT = 100;
const MyIngredients: React.FC = () => {
  const [ingredient, setIngredient] = useState<Grocery>();

  const [open, setOpen] = useState<boolean>(false);
  const { data, isLoading, query, onSearch } = useSearch('grocery', PAGE_LIMIT);
  const { item1: result, item2: totalCount } =
    (data as SearchResult<Grocery>) || {};

  const updateIngredient = (newIngredient: Grocery) => {
    setIngredient(newIngredient);
    setOpen(true);
  };
  useEffect(() => {
    onSearch('a', 0, 500);
  }, []);
  return (
    <PageContent>
      <Main justify='center' align='center'>
        <Box width='xlarge'>
          <Search results={PAGE_LIMIT} type='grocery' showButton />
        </Box>
        <Box align='center' justify='center' width='xlarge'>
          {isLoading ? (
            <IngredientList
              setIngredient={updateIngredient}
              ingredients={result}
            />
          ) : (
            <Box pad={'large'}>
              <Spinner pad='large' size='large' />
            </Box>
          )}
          <Pagination
            step={PAGE_LIMIT}
            onChange={({ startIndex }) => onSearch(query, startIndex)}
            numberItems={totalCount}
          />
        </Box>

        {open && (
          <Popup isOpen={open} onPopupClose={() => setOpen(false)}>
            <GroceryForm update grocery={ingredient} />
          </Popup>
        )}
      </Main>
    </PageContent>
  );
};

export default MyIngredients;
