import { AxiosRequestConfig } from 'axios';

export const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost:5001'
    : 'https://recipifyrr.azurewebsites.net';

export const ApiConfig: AxiosRequestConfig = {
  withCredentials: true,
  timeout: 60000,
  baseURL: API_URL,
  headers: {
    
      'Cache-control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-type': 'application/json',
      Accept: 'application/json',
    
  },
};
export const FormConfig = {
  withCredentials: true,
  timeout: 60000,
  baseURL: API_URL,
  headers: {
    common: {
      Pragma: 'no-cache',
      Accept: 'multipart/form-data',
    },
  },
};
