import groceryApi from 'Features/Grocery/GroceryApi';
import nutritionApi from 'Features/Grocery/NutritionApi';
import recipeApi from 'Features/Recipe/RecipeApi';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { SearchType, Suggestable, Suggestion } from '../types';

type State = {
  query?: string;
  suggestions?: Suggestable[];
};
const getSuggestions = (type: SearchType, limit: number, query?: string) => {
  const abortController = new AbortController();
  if (!query) {
    return Promise<Suggestable>;
  }
  switch (type) {
    case 'recipes':
      return recipeApi.suggestions(query, 0, limit, abortController);
    case 'grocery':
      return groceryApi.suggestions(query, 0, limit, abortController);
    case 'nutritions':
      return nutritionApi.suggestions(query, 0, limit, abortController);
    default:
      return Promise<Suggestable>;
  }
};

export default function useSuggestions(
  type: SearchType,
  externalSelect?: (id: string) => void,
  navigateToSearch = false,
  clickToSearch = false,
  limit = 10,
  threshold = 3,
): {
  isLoading: boolean;
  suggestions?: Suggestable[];
  query?: string;
  isFetching: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect:
    | ((x: { target: HTMLElement | null; suggestion: Suggestion }) => void)
    | undefined;
} {
  const [state, setState] = useState<State>({});
  const navigate = useNavigate();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      query: value,
    }));
  };

  const onSelect = ({ suggestion }: { suggestion: Suggestion }) => {
    const { query } = state;
    const { value, label } = suggestion;

    if (clickToSearch && query) {
      navigate(`/search?type=${type}&limit=${limit}&query=${query}`);
      setState((prevState) => ({
        ...prevState,
        query: '',
      }));
      return;
    }

    if (navigateToSearch && value) {
      navigate(`/${type}/${value}`);
      setState((prevState) => ({
        ...prevState,
        query: '',
      }));
      return;
    }

    if (externalSelect) {
      externalSelect(value);
      setState((prevState) => ({
        ...prevState,
        query: '',
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      query: label,
    }));
  };

  const { query } = state;
  const [search] = useDebounce(query, 50);
  const { isLoading, data, isFetching } = useQuery(
    [`${type}-${query}`],
    async () => {
      const result = await getSuggestions(type, limit, search);
      return result as Suggestable[];
    },
    {
      enabled: !!(search && search.length > threshold),
    },
  );
  return {
    suggestions: data,
    query,
    isLoading,
    isFetching,
    onChange,
    onSelect,
  };
}
