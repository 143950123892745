import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store/store';
import ErrorComponent from '../Error/Error';
// type withAuthenticationProps = {
//     user: User
// }
const withAuthentication =
  <P extends Record<string, unknown>>(
    Component: React.FunctionComponent<P>,
  ): React.FC<P> =>
  ({
    // user,
    ...props
  }) => {
    const user = useSelector<AppState>((state) => state.user.currentUser);
    return user == null ? (
      <ErrorComponent linkText='Logga in/Skapa konto' link='/login'>
        Du har inte tillgång till den här sidan.
        <br /> Vänligen logga in eller skapa konto.
      </ErrorComponent>
    ) : (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...(props as P)} />
    );
  };
export default withAuthentication;
