import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nutrition } from 'Components/Nutrition/Interfaces';
import { Suggestable } from 'Features/Search/types';
import nutritionApi from './NutritionApi';

export interface NutritionState {
  loading: boolean;
  nutritions?: Nutrition[];
  currentNutrition?: Nutrition;
  suggestions?: Suggestable[];
}

export const getNutrition = createAsyncThunk(
  'nutrition/byId',
  async (id: string) => {
    const result = await nutritionApi.byId(id);
    return result;
  },
);
export const getSuggestions = createAsyncThunk(
  'nutrition/suggestions',
  async (search: string) => {
    const abortController = new AbortController();
    const result = await nutritionApi.suggestions(
      search,
      0,
      25,
      abortController,
    );
    return result;
  },
);
const initialState: NutritionState = {
  nutritions: undefined,
  currentNutrition: undefined,
  suggestions: undefined,
  loading: false,
};
const nutritionSlice = createSlice({
  initialState,
  name: 'nutrition',
  reducers: {
    setNutritions: (state, action: PayloadAction<Nutrition[]>) => {
      state.nutritions = action.payload;
      state.loading = false;
    },
    setCurrentNutrition: (state, action: PayloadAction<Nutrition>) => {
      state.currentNutrition = action.payload;
      state.loading = false;
    },
    setSuggestions: (state, action: PayloadAction<Suggestable[]>) => {
      state.suggestions = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      state.loading = false;
      state.nutritions = undefined;
      state.currentNutrition = undefined;
      state.suggestions = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNutrition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNutrition.fulfilled, (state, action) => {
      state.currentNutrition = action.payload;
      state.loading = false;
    });
    builder.addCase(getNutrition.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSuggestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload;
      state.loading = false;
    });
    builder.addCase(getSuggestions.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const {
  setNutritions,
  setCurrentNutrition,
  setSuggestions,
  setLoading,
  reset,
} = nutritionSlice.actions;
export default nutritionSlice.reducer;
