import { css } from 'styled-components';

const IconStyles = css`
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  border: 0.1em solid rgba(255, 255, 255, 0);
  transition: 0.6s;
  &:hover {
    padding: 1px;
  }
`;

export default IconStyles;
