import Search from 'Features/Search/Components/Search';
import { Box, Carousel, Page, PageContent } from 'grommet';
import * as React from 'react';

const RecipesPage: React.FC = () => (
  <Page>
    <Box height='medium' width='medium' overflow='hidden'>
      <Carousel>
        <Box height='medium' width='medium' background='accent-1' />
        <Box height='medium' width='medium' background='accent-2' />
        <Box height='medium' width='medium' background='accent-3' />
      </Carousel>
    </Box>
    <PageContent>
      <Search type='recipes' />
    </PageContent>
  </Page>
);
export default RecipesPage;
