import { useEffect, useState } from 'react';

const useGetRandomBackgroundImage = (): string => {
  const [image, setImage] = useState<string>('');
  useEffect(() => {
    const images = [
      {
        id: 1,
        src: '/assets/img/banner01.jpg',
        title: 'Mat',
        description: 'bar',
      },
      {
        id: 2,
        src: '/assets/img/banner02.jpg',
        title: 'Mat',
        description: 'bar',
      },
    ];
    const random = Math.floor(Math.random() * Array.from(images).length);
    setImage(images[random].src);
  }, []);
  return image;
};
export default useGetRandomBackgroundImage;
