import { AxiosError } from 'axios';
import { Button, TextInput } from 'grommet';
import { Lock, User } from 'grommet-icons';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAppDispatch } from '../../Hooks/storeHooks';
import { loginUser } from '../../User/UserEffects';

const StyledLoginForm = styled.form`
  font-size: 1rem;
  margin-top: 0;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  &&& button {
    font-size: 1.2em;
  }
`;
const IconStyles = `
margin: 0em 0.6em;`;
const StyledUser = styled(User)`
  ${IconStyles}
`;
const StyledLock = styled(Lock)`
  ${IconStyles}
`;
const StyledErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.alertColor};
`;
const StyledInput = styled(TextInput)`
  margin: 1em 0em;
`;
interface InputState {
  userName: string;
  password: string;
  errorMessage?: string;
}

const Login: React.FC = () => {
  const [error, setError] = useState<string>();
  const { register, handleSubmit } = useForm<InputState>({
    defaultValues: {
      userName: '',
      password: '',
    },
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onSubmit = (data: InputState) => {
    const { userName, password } = data;

    try {
      loginUser(userName, password)(dispatch);
      navigate('/');
    } catch (apiError) {
      setError((apiError as AxiosError).message);
    }
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
      <StyledInput
        icon={<StyledUser spacing='large' color='black' />}
        required
        autoComplete='username'
        {...register('userName', { required: true })}
      />
      <StyledInput
        icon={<StyledLock color='black' />}
        placeholder='password'
        required
        autoComplete='current-password'
        type='password'
        {...register('password', { required: true })}
      />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      <StyledButtonContainer>
        <Button size='medium' primary type='submit' label='login' />
      </StyledButtonContainer>
    </StyledLoginForm>
  );
};
export default Login;
