/* eslint-disable */
import React from 'react';
import { Box, Footer as GrommetFooter, Nav, Text } from 'grommet/components';
import styled from 'styled-components';
import {
  Article,
  CircleInformation,
  Contact,
  Facebook,
  Home,
  Instagram,
  Notes,
  Twitter,
} from 'grommet-icons';
import { Link } from 'react-router-dom';

const StyledFooter = styled(GrommetFooter)`
  min-height: 4em;
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.68);
  color: #fff;
`;
const StyledList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
`;
const StyledLink = styled(Link)`
  &&& {
    display: flex;
    align-items: center;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    margin: 1em;
    svg {
      margin-right: 0.5em;
    }
    a {
      color: #fff;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter
      gap='none'
      height='15em'
      direction='column'
      background='brand'
    >
      <Nav pad='small' direction='row'>
        <StyledList>
          <li>
            <StyledLink to='/home'>
              <Home />
              Home
            </StyledLink>
          </li>
          <li>
            <StyledLink to='/recipes'>
              <Notes />
              Recipes
            </StyledLink>
          </li>
          <li>
            <StyledLink to='/blog'>
              <Article />
              Blog
            </StyledLink>
          </li>
          <li>
            <StyledLink to='/about'>
              <CircleInformation />
              About
            </StyledLink>
          </li>
          <li>
            <StyledLink to='/contact'>
              <Contact />
              Contact
            </StyledLink>
          </li>
        </StyledList>
      </Nav>

      <Box pad='medium' align='center' direction='row' gap='1em'>
        <Facebook />
        <Instagram />
        <Twitter />
      </Box>
      <Box align='center' justify='center'>
        <Box align='center' justify='center'>
          <Box pad='xsmall'>
            <Text>&copy; 2023 Rei. All Rights Reserved.</Text>
          </Box>
          <Box pad='xsmall' align='center' justify='center'>
            <Text>
              <Text>Privacy Policy </Text> <Text> | </Text>{' '}
              <Text> Terms of Service</Text>
            </Text>
          </Box>
        </Box>
      </Box>
    </StyledFooter>
  );
};

export default Footer;
