import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRecipe } from 'Features/Recipe/RecipeThunks';
import { FormType } from 'Types/Enums';
import ContentSection from '../../../../Components/Layout/ContentSection';
import RecipeControls from '../../../../Components/EditButton/EditButton';
import ErrorComponent from '../../../../Components/Error/Error';
import Popup from '../../../../Components/Popup/Popup';
import Recipe from '../Recipe/Recipe';
import { useIsEditingAllowed } from '../../../../Hooks/useIsEditingAllowed';
import { AppState } from '../../../../Store/store';
import { UserState } from '../../../../User/UserSlice';
import recipeApi from '../../RecipeApi';
import { useAppSelector } from '../../../../Hooks/storeHooks';
import RecipeForm from '../RecipeForm/RecipeForm';

const RecipePage: React.FC = () => {
  const { currentRecipe } = useAppSelector((state) => state.recipe);
  const { currentUser } = useSelector<AppState, UserState>(
    (state) => state.user,
  );
  const { id } = useParams();
  const editingAllowed = useIsEditingAllowed(currentUser, currentRecipe);
  const [edit, setEdit] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentRecipe) {
      const { id: recipeId } = currentRecipe;
      if (recipeId) {
        recipeApi.updateVisits(recipeId);
      }
    }
  }, [currentRecipe]);
  useEffect(() => {
    if (id && id !== currentRecipe?.id) {
      dispatch(getRecipe(id));
    }
  }, [id, dispatch, currentRecipe]);
  const onClick = async () => {
    setEdit(true);
  };
  const onPopupClose = () => {
    setEdit(false);
  };
  return (
    <ContentSection>
      {currentRecipe == null && (
        <ErrorComponent linkText='Vi kunde inte hitta något recept' link='/' />
      )}
      {currentRecipe && currentRecipe != null && editingAllowed && (
        <RecipeControls onClick={onClick} />
      )}
      {currentRecipe && currentRecipe != null && !edit && (
        <Recipe recipe={currentRecipe} />
      )}
      {currentRecipe && currentRecipe != null && currentRecipe && edit && (
        <Popup onPopupClose={onPopupClose} isOpen>
          <RecipeForm formType={FormType.UPDATE} />
        </Popup>
      )}
    </ContentSection>
  );
};
export default RecipePage;
