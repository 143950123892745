import groceryApi from 'Features/Grocery/GroceryApi';
import {
  deleteGroceries,
  deleteGrocery,
  getPaginated,
} from 'Features/Grocery/grocerySlice';
import { Grocery } from 'Features/Grocery/Interfaces';
import { CheckBox } from 'grommet';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import IngredientListItem from './IngredientListItem/IngredientListItem';

interface Props {
  ingredients?: Grocery[];
  setIngredient?: (ingredient: Grocery) => void;
}
const StyledSection = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding: 20px;
    max-width: 100%;
  }
`;
const StyledHeaderRow = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
`;
const StyledNameColumn = styled.div`
  margin-right: auto;
  margin-left: 0.5em;
`;
const StyledDensityColumn = styled.div`
  display: flex;
  margin-right: 15%;
`;
const StyledDensity = styled.div`
  margin: 0 2.5em;
`;
const IngredientList: React.FC<Props> = ({ ingredients, setIngredient }) => {
  const [state, setState] = useState<{
    checked: boolean;
    items: string[];
  }>({
    checked: false,
    items: [],
  });

  const dispatch = useDispatch();

  const checkAll = () => {
    const { checked } = state;
    if (!checked) {
      setState({
        checked: true,
        items: ingredients?.map(({ id }) => id) ?? [],
      });
      return;
    }
    setState({
      checked: false,
      items: [],
    });
  };

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    setState((prevState) => ({
      checked: prevState.checked,
      items: checked
        ? [...prevState.items, name]
        : prevState.items.filter((x) => x !== name),
    }));
  };
  const onDelete = async (id: string) => {
    const { items } = state;
    if (items && items.length > 0) {
      dispatch(deleteGroceries(items));
    } else {
      dispatch(deleteGrocery(id));
    }
    dispatch(getPaginated([0, 25]));
  };

  const onSave = async (ingredient: Grocery) => {
    await groceryApi.update(ingredient);
  };
  const { items, checked } = state;
  return (
    <StyledSection>
      <StyledHeaderRow>
        <CheckBox name='all' checked={checked} onChange={checkAll} />
        <StyledNameColumn>Namn</StyledNameColumn>
        <StyledDensityColumn>
          <StyledDensity>g/ml</StyledDensity>
          <StyledDensity>g/st</StyledDensity>
        </StyledDensityColumn>
      </StyledHeaderRow>
      {ingredients?.map((ingredient) => (
        <IngredientListItem
          onDelete={onDelete}
          onSave={onSave}
          onCheck={onCheck}
          checked={items.includes(ingredient.id)}
          setIngredient={setIngredient}
          key={ingredient?.id}
          ingredient={ingredient}
        />
      )) ?? <></>}
    </StyledSection>
  );
};

export default IngredientList;
