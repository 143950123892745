import Footer from 'Components/Footer/Footer';
import { Grommet } from 'grommet/components';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import Header from './Components/Header/Header';
import GlobalStyles from './GlobalStyles';
import * as serviceWorker from './serviceWorker';
import store from './Store/store';
import { darkTheme, defaultTheme } from './theme';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <Provider store={store}>
        <Grommet theme={defaultTheme}>
          <ThemeProvider theme={darkTheme}>
            <QueryClientProvider client={queryClient}>
              <GlobalStyles />
              <Header />
              <App />
              <Footer />
            </QueryClientProvider>
          </ThemeProvider>
        </Grommet>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
