export default function useMaxZIndex(): number {
  return (
    Array.from(document.querySelectorAll('body *'))
      .map((a) => parseFloat(window.getComputedStyle(a).zIndex))
      // eslint-disable-next-line no-restricted-globals
      .filter((a) => !isNaN(a))
      .sort((a, b) => b - a)
      .pop() ?? 1
  );
}
