import { AxiosError, AxiosResponse } from 'axios';
import { Recipe } from 'Features/Recipe/types';
import { ApiDataResponse, ExtendedMenu, SimplifiedMenu } from '../Types/types';
import Api from './Api';
import { ApiConfig } from './ApiConfig';

enum MenuApiEndpoints {
  GET = 'menus/generate',
  CREATE = 'menus/create',
  GET_BY_NAME_OR_ID = 'menus/bynameorid',
  GET_BY_USER_ID = 'menus/byuserid',
}

export default class MenuApi extends Api {
  /**
   * Generates an HTTP Request to get a random menu
   *
   * @returns {Promise<Array<Record<string, Recipe>>} - Array of menu items
   */
  public getMenu(days: string[]): Promise<Record<string, Recipe>> {
    return this.get<
      AxiosResponse<ApiDataResponse<Record<string, Recipe>>>,
      AxiosResponse<ApiDataResponse<Record<string, Recipe>>>
    >(
      `${MenuApiEndpoints.GET}?${days.map((day) => `&days=${day}`)}`.replaceAll(
        ',',
        '',
      ),
      ApiConfig,
    )
      .then(
        (response: AxiosResponse<ApiDataResponse<Record<string, Recipe>>>) => {
          const {
            data: { result },
          } = response;
          return result;
        },
      )
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get a single recipe by name or id
   *
   * @param {string} query - id or name of recipe to get
   * @returns {Promise<ExtendedMenu>}  a single recipe with specified id or name
   */
  public getByNameOrId(query: string): Promise<ExtendedMenu> {
    return this.get<
      ApiDataResponse<ExtendedMenu[]>,
      AxiosResponse<ApiDataResponse<ExtendedMenu[]>>
    >(
      `${MenuApiEndpoints.GET_BY_NAME_OR_ID}/?query=${query}&start=0&limit=1`,
      ApiConfig,
    )
      .then((response: AxiosResponse<ApiDataResponse<Array<ExtendedMenu>>>) => {
        const { data } = response;
        return data.result.length > 0 ? data.result[0] : ({} as ExtendedMenu);
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
  /**
   *
   * @param {string} id - Id of user to get recipes for
   * @returns {Promise<ExtendedMenu>} a s id
   */

  public async getByUserId(
    id: string,
    start: number,
    limit = 25,
  ): Promise<ExtendedMenu[]> {
    return this.get<
      ApiDataResponse<ExtendedMenu[]>,
      AxiosResponse<ApiDataResponse<ExtendedMenu[]>>
    >(
      `${MenuApiEndpoints.GET_BY_USER_ID}/?query=${id}&start=${start}&limit=${limit}`,
      ApiConfig,
    )
      .then((response: AxiosResponse<ApiDataResponse<ExtendedMenu[]>>) => {
        const { data } = response;
        return data.result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public saveMenu(data: SimplifiedMenu): Promise<unknown> {
    return this.post<unknown, SimplifiedMenu>(
      MenuApiEndpoints.CREATE,
      data,
      ApiConfig,
    ).then((response) => response?.data);
  }
}
