import RecipeCard from 'Features/Recipe/Components/RecipeList/RecipeListItem/RecipeCard';
import { Recipe } from 'Features/Recipe/types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ApiConfig } from '../../Api/ApiConfig';
import MenuApi from '../../Api/MenuApi';
import ContentSection from '../../Components/Layout/ContentSection';
import { ExtendedMenu } from '../../Types/types';
import { customSort, days, initialDays } from '../../Utils/Utils';

const StyledDiv = styled.div`
  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
`;
const MenuPage: React.FC = () => {
  const [currentMenu, setCurrentMenu] = useState<ExtendedMenu>();
  const params = useParams();
  useEffect(() => {
    const api = new MenuApi(ApiConfig);
    const getMenu = async () => {
      if (Object.keys(params).length > 0) {
        if (params.id) {
          const result: ExtendedMenu = await api.getByNameOrId(params.id);
          if (result) {
            setCurrentMenu(result);
          }
        }
      }
    };
    getMenu();
  }, [params]);

  return (
    <div>
      <ContentSection>
        <h2>{currentMenu?.name}</h2>
        <p>{currentMenu?.description}</p>
        <StyledDiv>
          <ul>
            {currentMenu?.recipes &&
              customSort(Object.entries(currentMenu.recipes), initialDays).map(
                (item) =>
                  item[1] && (
                    <li>
                      <h3>{days[item[0]]}</h3>
                      <RecipeCard row recipe={item[1] as Recipe} />
                    </li>
                  ),
              )}
          </ul>
        </StyledDiv>
      </ContentSection>
    </div>
  );
};
export default MenuPage;
