import type { AppStore } from './store';

// Wrapper to make sure we do not introduce dependency cyle
class StoreRegistry {
  private store: unknown;

  public register(store: AppStore) {
    this.store = store;
  }

  public getStore(): AppStore {
    return this.store as AppStore;
  }
}
const storeRegistry = new StoreRegistry();
export default storeRegistry;
