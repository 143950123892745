import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import useFormattedTime from 'Hooks/useFormattedTime';
import ShortInfo from 'Components/ShortInfo/ShortInfo';
import useBlobImage from 'Hooks/useBlobImage';
import LoadingSpinner from 'Components/LoadingSpinner/LoadingSpinner';
import { Recipe } from 'Features/Recipe/types';
import useGetRandomBackgroundImage from '../../../../../Hooks/useGetRandomBackgroundImage';

import { days } from '../../../../../Utils/Utils';

const StyledListItem = styled.li`
  list-style: none;
  max-width: 100%;
`;
const StyledMenuItem = styled.div<{ $selected: boolean }>`
  display: grid;
  height: 200px;
  width: 450px;
  max-width: 100%;
  grid-template-areas: 'date img' 'content img' 'content img';
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-template-rows: 0.1fr 0.2fr 1.7fr;
  grid-gap: 1em;
  position: relative;
  margin: 20px;
  padding: 20px;
  box-shadow: ${({ theme, $selected }) => $selected && theme.shadows.boxShadow};
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xsmall}) {
    grid-template-areas:
      'date date'
      'img img'
      'content content';
    margin: 10px;
    padding: 1em;
    height: 350px;
    align-items: center;
  }
`;
const StyledDate = styled.div`
  grid-area: date;
  h3 {
    margin: 0.3em 0;
  }
`;
const StyledImage = styled.div`
  overflow: hidden;
  grid-area: img;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    max-height: 100%;
    height: 300%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xsmall}) {
    justify-content: flex-start;
    height: 150px;
  }
`;

const StyledContent = styled.div`
  grid-area: content;
  h2 {
    margin: 0%;
    font-size: 1.1rem;
  }
  p {
    font-size: 0.9rem;
  }
  span:first-of-type {
    margin-right: 1.5em;
  }
`;
interface Props {
  recipe: Recipe;
  day: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addToList: (item: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeFromList: (item: any) => void;
}

const GeneratedMenuItem: React.FC<Props> = ({
  recipe,
  day,
  addToList,
  removeFromList,
}) => {
  const img = useGetRandomBackgroundImage();
  const [selected, setSelected] = useState<boolean>(false);
  const onClick = () => {
    if (selected) {
      removeFromList(day);
    } else {
      addToList(day);
    }
    setSelected(!selected);
  };
  const { id, name, description, portions, cookingTime } = recipe;
  const time = useFormattedTime(cookingTime);
  const { blob, loading } = useBlobImage(id);
  return (
    <StyledListItem>
      <StyledMenuItem
        $selected={selected}
        role='checkbox'
        tabIndex={0}
        onKeyDown={onClick}
        aria-checked={selected}
        onClick={onClick}
      >
        <StyledDate>
          <h3>{days[day]}</h3>
        </StyledDate>
        <StyledImage>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <img
              alt='saved recipe'
              src={(blob && URL.createObjectURL(blob)) || img}
            />
          )}
        </StyledImage>
        <StyledContent>
          <h2>{name}</h2>
          <ShortInfo time={time} portions={portions} />
          <p>{description}</p>
        </StyledContent>
      </StyledMenuItem>
    </StyledListItem>
  );
};
export default GeneratedMenuItem;
