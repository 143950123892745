import { ApiConfig } from 'Api/ApiConfig';
import MenuApi from 'Api/MenuApi';
import MenuList from 'Components/MenuList/MenuList';
import { useAppSelector } from 'Hooks/storeHooks';
import { useEffect, useState } from 'react';
import { ExtendedMenu } from 'Types/types';

const MyMenus: React.FC = () => {
  const [menus, setMenus] = useState<ExtendedMenu[]>();
  const { currentUser: user } = useAppSelector((state) => state.user);
  const { id } = user || {};
  useEffect(() => {
    const api = new MenuApi(ApiConfig);
    const getMenus = async () => {
      const response = id && (await api.getByUserId(id, 0, 25));
      if (response) {
        setMenus(response);
      }
    };
    getMenus();
  }, [id]);
  return (
    <div className='my-menus'>
      {menus && <MenuList initialRow={false} menus={menus} />}
    </div>
  );
};
export default MyMenus;
