/* eslint-disable jsx-a11y/label-has-associated-control */
import NutritionValueInput from 'Features/Grocery/Components/NutritionValueInput/NutritionValueInput';
import { Grocery, NutritionValue } from 'Features/Grocery/Interfaces';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'Store/store';
import { UserState } from 'User/UserSlice';

import groceryApi from 'Features/Grocery/GroceryApi';
import styled from 'styled-components';
import { Button, TextInput } from 'grommet';
import { Trash } from 'grommet-icons';
import IconStyles from 'Components/Icons/IconStyles';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1em;
  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.3em;
  }

  li {
    margin: 5px 0px;
    display: flex;
    align-items: center;
    p {
      font-size: 1em;
      margin: 0;
      margin-right: 0.7em;
    }
    svg {
      margin-right: 0.5em;
    }
  }
`;
const StylededColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const StyledInput = styled(TextInput)`
  margin-left: 1em;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(Button)`
  align-self: center;
  justify-self: center;
  width: 150px;
`;
const StyledTrash = styled(Trash)`
  ${IconStyles};
`;
interface Props {
  grocery?: Grocery;
  update?: boolean;
}
type InputState = Partial<Grocery>;
const GroceryForm: React.FC<Props> = ({ grocery, update }) => {
  const initialState: InputState = update
    ? {
        ...grocery,
      }
    : {};

  const [inputState, setInputState] = useState<InputState>(initialState);
  const { name, nutritions, weightPerMl, weightPerUnit } = inputState;

  const { currentUser } = useSelector<AppState, UserState>(
    (state) => state.user,
  );
  const addNutrition = (nutrition: NutritionValue) => {
    if (nutrition) {
      setInputState({
        ...inputState,
        nutritions: nutritions
          ? [...nutritions.filter((x) => x !== nutrition), nutrition]
          : [nutrition],
      });
    }
  };

  const removeNutrition = (x: NutritionValue) => {
    if (nutritions) {
      setInputState((state) => ({
        ...state,
        nutritions: nutritions.filter((y) => y !== x),
      }));
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const key = event.currentTarget.name as keyof InputState;
    const value = event.currentTarget.value as string | boolean;
    setInputState((state) => ({
      ...state,
      [key]: value as unknown as Pick<InputState, keyof InputState>,
    }));
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currentUser == null) {
      return;
    }

    if (name && nutritions) {
      if (update && grocery) {
        const newGrocery: Grocery = {
          ...grocery,
        };
        groceryApi.update(newGrocery as Grocery);
        setInputState({});
        return;
      }

      const newGrocery: Omit<Grocery, 'id' | 'creator'> = {
        name,
        nutritions,
        weightPerMl: Number(weightPerMl),
        weightPerUnit: Number(weightPerUnit),
        imported: false,
      };
      await groceryApi.create(newGrocery);

      setInputState({});
    }
  };

  const reset = () => setInputState({});
  return (
    <StyledForm onSubmit={onSubmit}>
      <label htmlFor='name'>
        <TextInput name='name' onChange={onChange} value={name as string} />
      </label>
      <StylededColumn>
        <label htmlFor='weightPerMl'>
          Weight per ml:
          <TextInput
            onChange={onChange}
            type='number'
            value={weightPerMl}
            step={0.1}
            name='weightPerMl'
          />
        </label>
        <label htmlFor='weightPerUnit'>
          Weight per unit:
          <StyledInput
            onChange={onChange}
            type='number'
            value={weightPerUnit}
            step={0.1}
            name='weightPerUnit'
          />
        </label>
      </StylededColumn>

      {nutritions && (
        <ul>
          {nutritions?.map((x) => (
            <li key={x.nutrition.name}>
              <p>
                {x.nutrition.name} {x.value}
                {x.unit}
              </p>
              <StyledTrash onClick={() => removeNutrition(x)} />
            </li>
          ))}
        </ul>
      )}
      <NutritionValueInput addNutrition={addNutrition} />
      <StyledButtonContainer>
        <StyledButton onClick={reset} type='button' label='reset' />
        <StyledButton primary type='submit' label='save' />
      </StyledButtonContainer>
    </StyledForm>
  );
};
export default GroceryForm;
