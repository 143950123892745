import LoginButton from 'Components/LoginButton/LoginButton';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledMenu = styled.nav`
  &&& {
    display: flex;
    font-size: 1.3em;
    color: #fff;
    a {
      color: '#fff';
    }
    padding: 0.5em 0em;
  }
`;

const StyledLink = styled(Link)`
  list-style: none;
  padding: 14px 10px 10px;
  cursor: pointer;
  position: relative;
  color: #fff;
  &::after {
    content: '';
    position: absolute;
    height: 0.1em;
    left: 0.7em;
    right: 0.7em;
    bottom: 0;
    background: currentColor;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: -webkit-transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out;
  }
  &:hover,
  &:focus {
    &::after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
`;
const Menu: React.FC = () => (
  <StyledMenu>
    <StyledLink to='/menus'>Menus</StyledLink>
    <StyledLink to='/recipes'>Recipes</StyledLink>
    <StyledLink to='/inspiration'>Inspiration</StyledLink>
    <LoginButton />
  </StyledMenu>
);
export default Menu;
