import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoUrl from '../../Assets/logo.png';

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  img {
    height: 30px;
    cursor: pointer;
    font-family: 'Roboto';
  }
`;
const Logo: React.FC = () => (
  <StyledLogo>
    <Link to='/'>
      <img
        loading='lazy'
        alt='recipifyrr logo'
        className='logo--img'
        src={logoUrl}
      />
    </Link>
  </StyledLogo>
);

export default Logo;
