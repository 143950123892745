import groceryApi from 'Features/Grocery/GroceryApi';
import nutritionApi from 'Features/Grocery/NutritionApi';
import recipeApi from 'Features/Recipe/RecipeApi';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Searchable, SearchResult, SearchType } from '../types';

type State = {
  start: number;
  limit: number;
  query?: string;
};
const search = (
  type: SearchType,
  start: number,
  limit: number,
  query?: string,
) => {
  const abortController = new AbortController();
  if (!query) {
    return Promise<SearchResult<Searchable>>;
  }
  switch (type) {
    case 'recipes':
      return recipeApi.search(query, start, limit, abortController);
    case 'grocery':
      return groceryApi.search(query, start, limit, abortController);
    case 'nutritions':
      return nutritionApi.search(query, start, limit, abortController);
    default:
      return Promise<SearchResult<Searchable>>;
  }
};

export default function useSearch(
  type: SearchType,
  initialLimit = 25,
): {
  isLoading: boolean;
  isFetching: boolean;
  data: unknown;
  query?: string;
  onSearch: (value?: string, start?: number, limit?: number) => void;
} {
  const [state, setState] = useState<State>({
    start: 0,
    limit: initialLimit,
  });
  const onSearch = (value?: string, start = 0, limit = 25) =>
    setState({
      query: value,
      start,
      limit,
    });
  const { query, start } = state;
  const { isLoading, isFetching, data } = useQuery(
    [`${type}-${query}`],
    async () => {
      const result = await search(type, start, initialLimit, query);
      return result;
    },
  );
  return { isLoading, isFetching, data, query, onSearch };
}
