import { FunctionObject } from 'Features/Interfaces/FunctionObject';
import * as React from 'react';
import styled from 'styled-components';

const StyledNav = styled.nav`
display: flex;
margin-top: 20px;
margin-block-start: 0em;
margin-block-end: 1em;
padding-inline-start: 0px;
}
`;
// .my-page--menu-item {
//   list-style: none;

//   background: $content-background-color;
//   padding: 10px;
//   margin: 10px;
//   box-shadow: 0px 0px 5px 1px $lightgrey;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   font-size: 1.5em;
//   &:hover{
//       background: darken($content-background-color, 25%);
//   }
// }
const StyledNavItem = styled.div`
  background: ${({ theme }) => theme.colors.contentBackgroundColor};
  padding: 10px;
  margin: 10px;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5em;
  &:hover {
    filter: brightness(75%);
  }
`;
interface Props {
  functions: FunctionObject[];
  onClick: (object: FunctionObject) => void;
}
const MyPageMenu: React.FC<Props> = ({ functions, onClick }) => (
  <StyledNav role='navigation'>
    {functions.map((x) => (
      <StyledNavItem
        key={x.id}
        tabIndex={0}
        role='button'
        onKeyDown={() => onClick(x)}
        onClick={() => onClick(x)}
      >
        <span className='my-page--item-text'>{x.name}</span>
      </StyledNavItem>
    ))}
  </StyledNav>
);
export default MyPageMenu;
