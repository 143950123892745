import { AddCircle, SubtractCircle } from 'grommet-icons';
import { Box, Text } from 'grommet';
import * as React from 'react';
import styled from 'styled-components';
import IconStyles from 'Components/Icons/IconStyles';

interface Props {
  increase: (event: React.MouseEvent | React.TouchEvent) => void;
  decrease: (event: React.MouseEvent | React.TouchEvent) => void;
  portions: number;
}
const StyledPlus = styled(AddCircle)`
  ${IconStyles};
`;
const StyledMinus = styled(SubtractCircle)`
  ${IconStyles};
`;
const PortionsCounter: React.FC<Props> = ({ increase, decrease, portions }) => (
  <Box justify='center' align='center' direction='row' gap='1em'>
    <StyledMinus
      size='40px'
      onClick={decrease}
      role='button'
      color='accent-1'
    />
    <Text weight='bold'>
      <strong>{portions}</strong> servings
    </Text>
    <StyledPlus size='40px' onClick={increase} role='button' color='accent-1' />
  </Box>
);
export default PortionsCounter;
