import IconStyles from 'Components/Icons/IconStyles';
import { Ingredient, Unit } from 'Features/Recipe/types';
import { Box, Select, TextInput } from 'grommet';
import { Trash } from 'grommet-icons';
import * as React from 'react';
import styled from 'styled-components';

const StyledListItem = styled.li``;
const StyledBox = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    cursor: grab;
  }
`;
const StyledName = styled(Box)`
  margin-right: 20px;
  margin-left: 1.2rem;
  font-size: 1.1em;
  margin-right: auto;
`;
const StyledValues = styled(Box)`
  margin-right: 0 auto;
`;
const StyledRemove = styled(Trash)`
  ${IconStyles}
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
`;
const StyledTextInput = styled(TextInput)`
  &&& {
    margin-right: 1rem;
    width: 10em;
  }
`;
const StyledSelect = styled(Select)``;
interface Props {
  ingredient: Ingredient;
  index: number;
  units?: Record<number, string>;
  onQuantityChange: (ingredient: Ingredient, value: number) => void;
  onUnitChange: (
    ingredient: Ingredient,
    item: { label: string; value: Unit },
  ) => void;
  onRemove: (ingredient: Ingredient) => void;
  setDragStart: (index: number) => void;
  setDragOverItem: (index: number) => void;
  setDrop: () => void;
}
const IngredientInput: React.FC<Props> = ({
  ingredient,
  index,
  onQuantityChange,
  onUnitChange,
  onRemove,
  setDragStart,
  setDragOverItem,
  setDrop,
  units,
}) => {
  const onDragStart = async (event: React.DragEvent<HTMLLIElement>) => {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData(
      'image',
      event.currentTarget as unknown as string,
    );
    event.dataTransfer.setDragImage(event.currentTarget as Element, 30, 30);
    setDragStart(index);
  };
  const onDragOver = async (event: React.DragEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    setDragOverItem(index);
  };
  const onDrop = async () => {
    setDrop();
  };
  return (
    <StyledListItem
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      key={ingredient.grocery.name}
    >
      <StyledBox pad='small' background='accent-4' elevation='small'>
        <StyledName width='12em'>{ingredient.grocery.name}</StyledName>
        <StyledValues direction='row' justify='end'>
          <StyledTextInput
            size='medium'
            type='number'
            value={ingredient.quantity}
            name='quantity'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onQuantityChange(ingredient, +event.target.value)
            }
          />
          <StyledSelect
            margin={{
              right: '36px',
            }}
            size='small'
            options={Object.entries(units ?? {}).map((kvp) => ({
              label: kvp[1],
              value: kvp[0],
            }))}
            onChange={({ value: nextValue }) =>
              onUnitChange(ingredient, nextValue)
            }
          />
          <StyledRemove size='28px' onClick={() => onRemove(ingredient)} />
        </StyledValues>
      </StyledBox>
    </StyledListItem>
  );
};
export default IngredientInput;
