import { Button, TextArea } from 'grommet';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.div`
    margin: 10px 0px;
    position: relative;
    textarea {
\      width: 100%;
      height: auto;
      min-height: 10em;
    }
  }
  `;
const StyledButton = styled(Button)`
  margin-top: 10px;
  max-width: 10em;
  margin-right: 0;
  margin-left: auto;
`;
interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  useButton?: boolean;
  onButtonClick?: (value: string) => void;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  buttonText?: string;
  maxLength?: number;
  rows?: number;
  cols?: number;
  externalValue?: string;
  required?: boolean;
}
const TextAreaInput: React.FC<Props> = ({
  name,
  placeholder,
  label,
  useButton,
  onButtonClick,
  handleChange,
  buttonText,
  externalValue,
  required,
  ...otherProps
}) => {
  const [value, setValue] = useState<string>('');

  const onChange = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };
  const onClick = async () => {
    if (useButton && onButtonClick) {
      onButtonClick(value);
      setValue('');
    }
  };
  return (
    <StyledTextArea>
      {label && <label htmlFor={name}>{label}</label>}
      <TextArea
        resize={false}
        name={name}
        value={externalValue || value}
        onChange={handleChange || onChange}
        placeholder={placeholder}
        required={required}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      {useButton && (
        <StyledButton
          primary
          onClick={onClick}
          type='button'
          label={buttonText}
        />
      )}
    </StyledTextArea>
  );
};
export default TextAreaInput;
