import Api from 'Api/Api';
import { ApiConfig } from 'Api/ApiConfig';
import { ApiResponse } from 'Api/Interfaces/ApiResponse';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Nutrition } from 'Components/Nutrition/Interfaces';
import { SearchResult, Suggestable } from 'Features/Search/types';

enum Endpoints {
  GET = '/',
  SUGGESTIONS = '/suggestions',
  SEARCH = '/search',
  CREATE = '/create',
  UPLOAD = '/upload',
  UPDATE = '/update',
  DELETE = '/delete',
}

export class NutritionApi extends Api {
  private baseUrl: string;

  constructor(config: AxiosRequestConfig) {
    super(config);
    this.baseUrl = 'nutritions';
    this.search = this.search.bind(this);
    this.suggestions = this.suggestions.bind(this);
  }

  /**
   * Get nutrition by id
   * @param {string} id - Id of nutrition to get
   * @returns {Promise<Nutrition>} a single nutrition with specified id
   */
  public byId(id: string): Promise<Nutrition> {
    return this.get<ApiResponse<Nutrition>>(
      `${this.baseUrl}${Endpoints.GET}${id}`,
    ).then((response: AxiosResponse<ApiResponse<Nutrition>>) => {
      const {
        data: { result },
      } = response;
      return result;
    });
  }

  /**
   * Generates an HTTP Request to get a simplified version of the nutritions
   *
   * @param {string} query - String to search from
   * @returns {Promise<Suggestable[]>} an array of search suggestions
   */

  public suggestions(
    query: string,
    start: number,
    limit: number,
    abortController: AbortController,
  ): Promise<Suggestable[]> {
    return this.get<ApiResponse<Suggestable[]>>(
      `${this.baseUrl}${Endpoints.SUGGESTIONS}?query=${query}&start=${start}&limit=${limit}`,
      {
        ...this.config,
        signal: abortController.signal,
      },
    )
      .then((response: AxiosResponse<ApiResponse<Suggestable[]>>) => {
        const { data } = response;
        return data.result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get a list of nutritions
   *
   * @param {string} query - String to search from
   * @param {number} start - Start position of search result
   * @param {number} limit - Amount of nutritions to get
   * @returns {Promise<[Nutrition, number]>} an array of search suggestions
   */
  public search(
    query: string,
    start: number,
    limit: number,
    abortController: AbortController,
  ): Promise<SearchResult<Nutrition>> {
    return this.get<ApiResponse<SearchResult<Nutrition>>>(
      `${this.baseUrl}${Endpoints.SEARCH}/?query=${query}&start=${start}&limit=${limit}`,
      {
        ...this.config,
        signal: abortController.signal,
      },
    )
      .then((response: AxiosResponse<ApiResponse<SearchResult<Nutrition>>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Adds a new nutrition to the database
   * @param {Nutrition}} nutrition - Nutrition to be added
   * @returns {Promise<boolean>} true if the request was successful
   */
  public create(nutrition: Nutrition): Promise<boolean> {
    return this.post<AxiosResponse, Nutrition, AxiosResponse<unknown>>(
      Endpoints.CREATE,
      nutrition,
    )
      .then((response) => response.status === 200)
      .catch(() => false);
  }

  /**
   * Update a nutrition in the database
   * @param {Nutrition} nutrition - nutrition to be updated
   * @returns {Promise<boolean>} true if the request was successful
   *
   * */
  public update(nutrition: Nutrition): Promise<boolean> {
    const { id } = nutrition;
    return this.put<AxiosResponse, Nutrition, AxiosResponse<unknown>>(
      `${this.baseUrl}${id}`,
      nutrition,
    )
      .then((response: AxiosResponse) => {
        const { status } = response;
        return status === 200;
      })
      .catch(() => false);
  }

  /**
   * Delete a nutrition from the system
   * @param {string} id - Id of the nutrition to be deleted
   * @returns {Promise<boolean>} a status code of the operation
   */
  public remove(id: string): Promise<boolean> {
    return this.delete(`${this.baseUrl}/${id}`)
      .then((response) => {
        const { status } = response;
        return status === 200;
      })
      .catch(() => false);
  }

  /**
   *
   * @param {string} id - Id of nutrition to update
   * @returns {boolean} - Is updated or
   *
   */
}
const nutritionApi = new NutritionApi(ApiConfig);
export default nutritionApi;
