import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { Heading } from 'grommet';
import { Grid, List } from 'grommet-icons';
import { Recipe } from 'Features/Recipe/types';
import RecipeCard from './RecipeListItem/RecipeCard';

interface Props {
  title?: string;
  recipes?: Recipe[];
  showToggle?: boolean;
}
const StyledSection = styled.section`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    color: ${({ theme }) => theme.colors.textColor};
  }
  h2 {
    text-align: center;
  }
`;
const StyledList = styled.div<{ row: boolean }>`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ row }) => (row ? '100%' : '250px')}, 1fr)
  );
  grid-auto-rows: ${({ row }) => (row ? '100px' : '350px')};
  grid-gap: 1.5em;
  width: 100%;
`;

const StyledListIcon = styled(List)`
  font-size: 1.5em;
  align-self: flex-end;
  margin-bottom: 1.5em;
  cursor: pointer;
`;
const StyledGridIcon = styled(Grid)`
  font-size: 1.5em;
  align-self: flex-end;
  margin-bottom: 1.5em;
  cursor: pointer;
`;
const RecipeList: React.FC<Props> = ({ title, recipes, showToggle }) => {
  const [row, setRow] = useState<boolean>(false);
  const onClick = () => {
    setRow(!row);
  };
  return (
    <StyledSection>
      {title && (
        <Heading margin='2em' level={2}>
          {title}
        </Heading>
      )}
      {showToggle &&
        (row ? (
          <StyledGridIcon onClick={onClick} />
        ) : (
          <StyledListIcon onClick={onClick} />
        ))}
      <StyledList row={row}>
        {recipes?.map((recipe) => (
          <RecipeCard key={recipe.id} recipe={recipe} row={row} />
        )) ?? <></>}
      </StyledList>
    </StyledSection>
  );
};

export default RecipeList;
