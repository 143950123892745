import { Box, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

export interface Props {
  index: number;
  name: string;
}
const StyledItem = styled.li`
  list-style: none;
`;
const StyledDone = styled(Box)`
  background-color: rgba(0, 255, 0, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  svg {
    color: rgba(3, 99, 3, 0.9);
    margin: 12.5px;
  }
`;
const StyledWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 2em;
  margin-bottom: 15px;
  position: relative;
`;

const Instruction: React.FC<Props> = ({ index, name }) => {
  const [done, setDone] = useState<boolean>(false);
  const onClick = async () => {
    setDone(!done);
  };
  return (
    <StyledItem>
      <StyledWrapper
        style={{
          width: '100%',
          height: '100%',
        }}
        role='button'
        onKeyDown={() => setDone(!done)}
        onClick={onClick}
        tabIndex={0}
      >
        <Text size='large'>
          <strong>{index + 1}.</strong> {name}
        </Text>
        {done && (
          <StyledDone dir='row'>
            <Checkmark color='currentColor' />
          </StyledDone>
        )}
      </StyledWrapper>
    </StyledItem>
  );
};
export default Instruction;
