import RecipeList from 'Features/Recipe/Components/RecipeList/RecipeList';
import { Recipe } from 'Features/Recipe/types';
import * as React from 'react';
import styled from 'styled-components';

const StyledNotFound = styled.div`
  display: flex;
  justify-content: center;
`;
interface Props {
  result?: Recipe[];
}
const SearchResult: React.FC<Props> = ({ result }) => (
  <section>
    {result && result?.length > 0 ? (
      <RecipeList recipes={result} />
    ) : (
      <StyledNotFound>
        <p>Search yielded no result</p>
      </StyledNotFound>
    )}
  </section>
);

export default SearchResult;
