import * as React from 'react';
import styled from 'styled-components';
import useProgressSteps from '../Hooks/useProgressSteps';

const StyledBar = styled.div<{ $maxWidth: string }>`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  min-width: 100px;
  transition: background + color cubic-bezier(0.075, 0.82, 0.165, 1);
  max-width: ${({ $maxWidth }) => $maxWidth}px;
`;
interface Props {
  max: number;
  current: number;
}
const ProgressBar: React.FC<Props> = ({ max, current }) => {
  const { steps, amount } = useProgressSteps(max, current);
  return <StyledBar $maxWidth={(amount * 60).toString()}>{steps}</StyledBar>;
};
export default ProgressBar;
