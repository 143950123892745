import { getNutrition } from 'Features/Grocery/nutritionsSlice';
import Search from 'Features/Search/Components/Search';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledSection = styled.section`
  width: 100%;
`;
const SearchNutrition: React.FC = () => {
  const dispatch = useDispatch();
  const onSelect = (id: string) => {
    dispatch(getNutrition(id));
  };
  return (
    <StyledSection>
      <Search onSelect={onSelect} height={4} type='nutritions' results={25} />
    </StyledSection>
  );
};
export default SearchNutrition;
