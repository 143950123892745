import * as React from 'react';
import { useForm } from 'react-hook-form';
import Button from 'Components/Button/Button';
import TextInput from 'Features/Forms/TextInput/TextInput';
import userApi from 'User/UserApi';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from 'Hooks/storeHooks';
import { loginUser } from 'User/UserEffects';
import { NewUser } from '../../User/Interfaces/NewUser';

interface InputState extends NewUser {
  password: string;
  passwordConfirm: string;
}
const StyledForm = styled.form`
  width: 450px;
  max-width: 100%;
`;
const StyledButton = styled(Button)`
  margin-top: 1em;
`;
const CreateUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<InputState>({
    defaultValues: {
      firstName: '',
      familyName: '',
      userName: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
  });
  const navigate = useNavigate();
  const onSubmit = async ({
    userName,
    password,
    firstName,
    familyName,
    email,
  }: InputState) => {
    const newUser: NewUser = {
      userName,
      firstName,
      familyName,
      password,
      email,
    };

    await userApi.create(newUser);
    dispatch(loginUser(userName, password));
    navigate('/');
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        label='Användarnamn:'
        type='text'
        autoComplete='username'
        error={errors.userName}
        {...register('userName', { required: 'Användarnamn krävs' })}
      />
      <TextInput
        label='Epost:'
        type='email'
        autoComplete='email'
        error={errors.email}
        {...register('email', { required: 'Epost måste anges' })}
      />
      <TextInput
        label='Förnamn:'
        type='text'
        autoComplete=''
        error={errors.familyName}
        {...register('firstName', { required: 'Förnamn måste anges.' })}
      />
      <TextInput
        label='Efternamn:'
        type='text'
        autoComplete=''
        error={errors.familyName}
        {...register('familyName', { required: 'Efternamn måste anges' })}
      />
      <TextInput
        {...register('password', {
          required: true,
        })}
        label='Lösenord:'
        name='password'
        type='password'
        autoComplete='new-password'
      />
      <TextInput
        {...register('passwordConfirm', {
          required: true,
          validate: (value: string) => {
            const { password } = getValues();
            return password === value || 'Passwords should match!';
          },
        })}
        label='Bekräfta lösenord:'
        required
        name='passwordConfirm'
        type='password'
        autoComplete='new-password'
        error={errors.passwordConfirm}
      />
      <StyledButton type='submit' disabled={!isValid} inverted={false}>
        Skapa användare
      </StyledButton>
    </StyledForm>
  );
};

export default CreateUser;
