import * as React from 'react';

import styled from 'styled-components';
import { defaultTheme } from 'theme';

const StyledStep = styled.div`
  border-radius: 50%;
  background: ${defaultTheme.global?.colors?.['accent-4']};
  height: 15px;
  width: 15px;
  margin: 0 20px;
  -webkit-box-shadow: inset 0px 0px 2px -1px darken(${defaultTheme.global?.colors?.['accent-4']};, 50%);
  box-shadow: inset 0px 0px 2px -1px darken(${defaultTheme.global?.colors?.['accent-4']};, 50%);
  transition: background-color 0.2s ease-in-out;

  &.__visited {
    background: ${defaultTheme.global?.colors?.['accent-1']};
    -webkit-box-shadow: inset 0px 0px 2px -1px darken(${defaultTheme.global?.colors?.['accent-1']}, 50%);
    box-shadow: inset 0px 0px 2px -1px darken(${defaultTheme.global?.colors?.['accent-1']}, 50%);
  }
`;
interface Props {
  active: boolean;
}
const ProgresStep: React.FC<Props> = ({ active }) => (
  <StyledStep className={active ? '__visited' : undefined} />
);

export default ProgresStep;
