import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SearchResult, Suggestable } from 'Features/Search/types';
import Api from '../../Api/Api';
import { ApiConfig } from '../../Api/ApiConfig';
import { ApiResponse } from '../../Api/Interfaces/ApiResponse';
import { UpdateImagRequest } from '../../Api/Interfaces/UpdateImagRequest';
import { CreatedRecipe, Recipe } from './types';

enum Endpoints {
  GET = '/',
  RECENT = '/recent',
  POPULAR = '/popular',
  RANDOM = '/random',
  RANGE = '/range',
  BY_USER_ID = '/user',
  SUGGESTIONS = '/suggestions',
  SEARCH = '/search',
  CREATE = '/create',
  UPLOAD = '/upload',
  UPDATE_IMAGE = '/update/image',
  UPDATE = 'update',
  UPDATE_VISITS = 'update/visits',
  DELETE = 'delete',
}

export class RecipeApi extends Api {
  private baseUrl;

  constructor(config: AxiosRequestConfig) {
    super(config);
    this.baseUrl = `/recipes`;
    this.recent = this.recent.bind(this);
    this.search = this.search.bind(this);
    this.create = this.create.bind(this);
  }

  /**
   * Generates an HTTP Request to get recent recipes
   * @param {number} limit - amount of recipes to get
   * @returns {Promise<Recipe[]>} an array of Recipes
   */
  public recent(limit = 25): Promise<Recipe[]> {
    return this.get<ApiResponse<Recipe[]>>(
      `${this.baseUrl}${Endpoints.RECENT}?limit=${limit}`,
      ApiConfig,
    ).then((response: AxiosResponse<ApiResponse<Recipe[]>>) => {
      const {
        data: { result },
      } = response;
      return result;
    });
  }

  /**
   * Generates an HTTP Request to get popular recipes
   * @param {number} limit - amount of recipes to get
   * @returns {Promise<Recipe[]>} an array of Recipes wrapped in a promise
   */
  public popular(limit = 25): Promise<Array<Recipe>> {
    return this.get<ApiResponse<Recipe[]>>(
      `${this.baseUrl}${Endpoints.POPULAR}?limit=${limit}`,
      ApiConfig,
    ).then((response: AxiosResponse<ApiResponse<Recipe[]>>) => {
      const {
        data: { result },
      } = response;
      return result;
    });
  }
  /**
   * Get recipe by id
   * @param {string} id - Id of recipe to get
   * @returns {Promise<Recipe>} a single recipe with specified id
   */

  public byId(id: string): Promise<Recipe> {
    return this.get<ApiResponse<Recipe>>(`${this.baseUrl}/${id}`).then(
      (response: AxiosResponse<ApiResponse<Recipe>>) => {
        const {
          data: { result },
        } = response;
        return result;
      },
    );
  }

  /**
   * Get all recipes for user with specified id
   * @param {string} id - Id of user to get recipes for
   * @returns {Promise<Recipe[]>} - all recipes for user
   */

  public async byUserId(id: string): Promise<Recipe[]> {
    return this.get<ApiResponse<Recipe[]>>(
      `${this.baseUrl}${Endpoints.BY_USER_ID}/${id}`,
    )
      .then((response: AxiosResponse<ApiResponse<Recipe[]>>) => {
        const { data } = response;
        return data.result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get a single random recipe
   * @returns {Promise<Recipe>}  a single recipe with specified id or name
   */
  public random(): Promise<Recipe> {
    return this.get<ApiResponse<Recipe>>(`${this.baseUrl}${Endpoints.RANDOM}`)
      .then((response: AxiosResponse<ApiResponse<Recipe>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get a simplified version of the recipes
   *
   * @param {string} query - String to search from
   * @returns {Promise<Suggestable[]>} an array of search suggestions
   */
  public async suggestions(
    query: string,
    start: number,
    limit: number,
    abortController: AbortController,
  ): Promise<Suggestable[]> {
    return this.get<ApiResponse<Suggestable[]>>(
      `${this.baseUrl}${Endpoints.SUGGESTIONS}?query=${query}&start=${start}&limit=${limit}`,
      {
        ...this.config,
        signal: abortController.signal,
      },
    ).then((response: AxiosResponse<ApiResponse<Suggestable[]>>) => {
      const {
        data: { result },
      } = response;
      return result;
    });
  }

  /**
   * Generates an HTTP Request to get a list of recipes
   *
   * @param {string} query - String to search from
   * @param {number} start - Start position of search result
   * @param {number} limit - Amount of recipes to get
   * @returns {Promise<Recipe, number>} an array of search suggestions and total count
   */
  public search(
    query: string,
    start: number,
    limit: number,
    abortController: AbortController,
  ): Promise<SearchResult<Recipe>> {
    return this.get<ApiResponse<SearchResult<Recipe>>>(
      `${this.baseUrl}${Endpoints.SEARCH}?query=${query}&start=${start}&limit=${limit}`,
      {
        ...this.config,
        signal: abortController.signal,
      },
    ).then((response: AxiosResponse<ApiResponse<SearchResult<Recipe>>>) => {
      const {
        data: { result },
      } = response;
      return result;
    });
  }

  /**
   * Update fileinformation to connect a file to a recipe
   *
   * @param {string} imageUrl  - url of the image
   * @param {string} recipeId - recipe to add image to
   * @returns {Promise<unknown>} - the request promise
   */
  public updateImage(imageUrl: string, recipeId: string): Promise<unknown> {
    const request = {
      imageUrl,
    };
    return this.post<AxiosResponse, UpdateImagRequest, AxiosResponse<unknown>>(
      `${this.baseUrl}${recipeId}/${Endpoints.UPDATE_IMAGE}`,
      request,
    );
  }

  /**
   * Adds a new recipe to the database
   * @param {Recipe}} recipe - Recipe to be added
   * @returns {Promise<string>} true if the request was successful
   */
  public create(recipe: CreatedRecipe): Promise<string> {
    return this.post<ApiResponse<string>, CreatedRecipe>(
      `${this.baseUrl}${Endpoints.CREATE}`,
      recipe,
    ).then((response) => response.data.result);
  }

  /**
   * Update a recipe in the database
   * @param {Recipe} recipe - recipe to be updated
   * @returns {Promise<boolean>} true if the request was successful
   *
   * */
  public update(recipe: Recipe): Promise<boolean> {
    const { id } = recipe;
    return this.put<AxiosResponse, Recipe, AxiosResponse<unknown>>(
      `${this.baseUrl}/${id}/${Endpoints.UPDATE}`,
      recipe,
    )
      .then((response: AxiosResponse) => {
        const { status } = response;
        return status === 200;
      })
      .catch(() => false);
  }

  /**
   * Delete a recipe from the system
   * @param {string} id - Id of the recipe to be deleted
   * @returns {Promise<boolean>} a status code of the operation
   */
  public remove(id: string): Promise<boolean> {
    return this.delete(`${this.baseUrl}/${id}/${Endpoints.DELETE}`)
      .then((response) => {
        const { status } = response;
        return status === 200;
      })
      .catch(() => false);
  }

  /**
   *
   * @param {string} id - Id of recipe to update
   * @returns {boolean} - Is updated or
   *
   */

  public updateVisits(id: string): Promise<boolean> {
    return this.post<boolean, string, AxiosResponse<boolean>>(
      `${this.baseUrl}/${id}/${Endpoints.UPDATE_VISITS}`,
      id,
    ).then((response: AxiosResponse<boolean>) => {
      const { data } = response;
      return data;
    });
  }
}
const recipeApi = new RecipeApi(ApiConfig);
export default recipeApi;
