import ImageCrop from 'Features/Recipe/Components/ImagePreview/ImagePreview';

import { useState } from 'react';
import styled from 'styled-components';
import FileInput from '../FileInput/FileInput';

interface Props {
  save: (image: string, title?: string) => void;
}
const MAX_IMAGE_SIZE = 8_000_000;
const StyledFileInput = styled(FileInput)`
  margin-top: 2em;
`;
const ImageInput: React.FC<Props> = ({ save }) => {
  const [file, setFile] = useState<File>();
  const onChange = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (!event) {
      return;
    }
    const { files } = event.currentTarget;
    if (files == null || files.length <= 0 || files[0].size > MAX_IMAGE_SIZE) {
      return;
    }

    setFile(files[0]);
  };

  return (
    <>
      <StyledFileInput
        placeholder='Upload image..'
        onChange={onChange}
        name='image'
      />
      <ImageCrop save={save} file={file} />
    </>
  );
};
export default ImageInput;
