import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'Hooks/storeHooks';
import { useDispatch } from 'react-redux';
import { getUnits } from 'Store/appSlice';
import { Ingredient, Unit } from 'Features/Recipe/types';
import { DragAndDropState } from '../../../Types/types';

import IngredientInput from './IngredientInput';

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

interface Props {
  ingredients: Ingredient[];
  setIngredients: (ingredients: Ingredient[]) => void;
  className?: string;
}
const IngriedientsInput: React.FC<Props> = ({
  ingredients,
  setIngredients,
  className,
}) => {
  const { units } = useAppSelector((x) => x.app);
  const dispatch = useDispatch();
  const initialDragAndDropState: DragAndDropState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [] as Ingredient[],
    updatedOrder: [] as Ingredient[],
  };
  const [dragAndDrop, setDragAndDrop] = useState<DragAndDropState>(
    initialDragAndDropState,
  );

  useEffect(() => {
    if (!units) {
      dispatch(getUnits());
    }
  }, [dispatch, units]);
  const onQuantityChange = (ingredient: Ingredient, value: number) => {
    const index = ingredients.findIndex(
      (x) => x.grocery.name === ingredient.grocery.name,
    );
    const newIngredients = ingredients.slice(0);
    newIngredients.splice(index, 1, { ...ingredient, quantity: value });
    setIngredients(newIngredients);
  };

  const onUnitChange = (
    ingredient: Ingredient,
    selection: { label: string; value: number },
  ) => {
    const { value } = selection;
    const index = ingredients.findIndex(
      (x) => x.grocery.name === ingredient.grocery.name,
    );
    const newIngredients = ingredients.slice(0);
    newIngredients.splice(index, 1, { ...ingredient, unit: value as Unit });
    setIngredients(newIngredients);
  };

  const removeIngredient = (ingredient: Ingredient) => {
    setIngredients(ingredients.filter((x) => x !== ingredient));
  };
  const setDragStart = async (index: number) => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: index,
      isDragging: true,
      originalOrder: ingredients,
    });
  };
  const setDragOver = async (index: number) => {
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom as number;
    const draggedTo = index;
    const itemDragged = newList[draggedFrom];

    const remainingItems = newList.filter(
      (item, currentIndex) => item && currentIndex !== draggedFrom,
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];
    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo,
      });
    }
  };

  const setDrop = async () => {
    setIngredients(dragAndDrop.updatedOrder as Ingredient[]);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  return (
    <StyledList className={className}>
      {ingredients.length > 0 &&
        ingredients?.map((x, i) => (
          <IngredientInput
            key={x.grocery.id}
            units={units}
            setDragStart={setDragStart}
            setDragOverItem={setDragOver}
            setDrop={setDrop}
            index={i}
            ingredient={x}
            onQuantityChange={onQuantityChange}
            onUnitChange={onUnitChange}
            onRemove={removeIngredient}
          />
        ))}
    </StyledList>
  );
};
export default IngriedientsInput;
