import IngredientsInput from 'Features/Forms/Ingredients/IngredientsInput';
import { getGrocery } from 'Features/Grocery/grocerySlice';
import { Ingredient } from 'Features/Recipe/types';
import Search from 'Features/Search/Components/Search';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledIngredients = styled(IngredientsInput)`
  margin-top: 20px;
`;
// TODO: Reactor to functional component
const Groceries: React.FC<{
  ingredients: Ingredient[];
  setIngredients: (ingredients: Ingredient[]) => void;
}> = ({ ingredients, setIngredients }) => {
  const dispatch = useDispatch();

  const onSelect = (id: string) => {
    dispatch(getGrocery(id));
  };
  return (
    <>
      <Search onSelect={onSelect} height={4} type='grocery' results={25} />
      <StyledIngredients
        ingredients={ingredients}
        setIngredients={setIngredients}
      />
    </>
  );
};
export default Groceries;
