import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Suggestable } from 'Features/Search/types';
import { Recipe } from './types';

export interface RecipeState {
  loading: boolean;
  recipes?: Recipe[];
  currentRecipe?: Recipe;
  suggestions?: Suggestable[];
}
const initialState: RecipeState = {
  loading: false,
};
const recipeSlice = createSlice({
  initialState,
  name: 'recipe',
  reducers: {
    setRecipes: (state, action: PayloadAction<Recipe[]>) => {
      state.recipes = action.payload;
      state.loading = false;
    },
    setCurrentRecipe: (state, action: PayloadAction<Recipe>) => {
      state.currentRecipe = action.payload;
      state.loading = false;
    },
    setSuggestions: (state, action: PayloadAction<Suggestable[]>) => {
      state.suggestions = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      state.loading = false;
      state.recipes = undefined;
      state.currentRecipe = undefined;
      state.suggestions = undefined;
    },
  },
});
export const {
  setRecipes,
  setCurrentRecipe,
  setSuggestions,
  setLoading,
  reset,
} = recipeSlice.actions;
export default recipeSlice.reducer;
