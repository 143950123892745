import * as React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'Hooks/storeHooks';
import _ from 'lodash';
import { Button } from 'grommet';
import { Recipe } from 'Features/Recipe/types';
import { ApiConfig } from '../../../../Api/ApiConfig';
import MenuApi from '../../../../Api/MenuApi';
import { Sortable } from '../../../../Types/types';
import CreateMenu from '../CreateMenu/CreateMenu';
import Popup from '../../../../Components/Popup/Popup';
import GeneratedMenuItem from './GeneratedMenuItem/GeneratedMenuItem';

const StyledMenu = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 768px;
  max-width: 100%;
`;
const StyledMenuList = styled.ul`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 20px;
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.3em;
`;
const StyledButton = styled(Button)`
  margin: 0px 20px;
`;
const GeneratedMenu: React.FC = () => {
  const [items, setItems] = useState<Record<string, Recipe>>();
  const [itemsToKeep, setItemsToKeep] = useState<Record<string, Recipe>>(
    {} as Record<string, Recipe>,
  );
  const initialDays = ['mon', 'tue', 'wen', 'thu', 'fri', 'sat', 'sun'];
  const [days, setDays] = useState<string[]>(initialDays);
  const [open, setOpen] = useState<boolean>(false);
  const { loggedIn } = useAppSelector((state) => state.user);
  const addToList = (day: string) => {
    if (items && itemsToKeep[day] == null) {
      setItemsToKeep({ ...itemsToKeep, [`${day}`]: items[day] });
      setDays([...days.filter((x) => x !== day)]);
    }
  };
  const removeFromList = (day: string) => {
    if (!days.includes(day)) {
      setDays((prevDays) => [...prevDays, day]);
    }
    setItemsToKeep(_.omit(itemsToKeep, [`${day}`]));
  };
  const getMenu = useCallback(async () => {
    const api = new MenuApi(ApiConfig);

    const response = await api.getMenu(days);
    if (response) {
      setItems({ ...response, ...itemsToKeep });
    }
  }, [days, itemsToKeep]);
  const getNewMenu = () => {
    getMenu();
  };

  const togglePopup = () => setOpen(!open);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customSort = (data: any[], sortBy: string[]) => {
    const sortByObject: Sortable = sortBy.reduce(
      (obj, item, index) => ({
        ...obj,
        [item]: index,
      }),
      {},
    );
    return data.sort((a, b) => sortByObject[a[0]] - sortByObject[b[0]]);
  };
  return (
    <StyledMenu>
      <StyledMenuList>
        {items &&
          customSort(Object.entries(items), initialDays).map(
            (item, index) =>
              item[1] && (
                <GeneratedMenuItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}${item[0]}-${item[1].id}`}
                  day={item[0]}
                  recipe={item[1]}
                  addToList={addToList}
                  removeFromList={removeFromList}
                />
              ),
          )}
      </StyledMenuList>
      <StyledButtonsContainer>
        <Button
          disabled={days.length === 0}
          primary
          type='button'
          onClick={getNewMenu}
          label='Fetch recipes'
        />
        {loggedIn && items && (
          <StyledButton type='button' onClick={togglePopup} label='Save' />
        )}
      </StyledButtonsContainer>
      {open ? (
        <Popup isOpen={open} onPopupClose={togglePopup}>
          <CreateMenu recipesWidthDays={items} />
        </Popup>
      ) : (
        <></>
      )}
    </StyledMenu>
  );
};
export default GeneratedMenu;
