import { Recipe } from 'Features/Recipe/types';
import ApiTypes from '../Api/ApiTypes';
import { Days, Sortable } from '../Types/types';

export const initialDays = ['mon', 'tue', 'wen', 'thu', 'fri', 'sat', 'sun'];
export const days: Days = {
  mon: 'Måndag',
  tue: 'Tisdag',
  wen: 'Onsdag',
  thu: 'Torsdag',
  fri: 'Fredag',
  sat: 'Lördag',
  sun: 'Söndag',
};
export const IsEmptyOrWhiteSpace = (
  testString?: string | null | undefined,
): boolean =>
  testString === null || testString === undefined || testString === '';

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const customSort = (
  data: string[] | [string, Recipe][],
  sortBy: string[],
): string[] | [string, Recipe][] => {
  const sortByObject: Sortable = sortBy.reduce(
    (obj, item, index) => ({
      ...obj,
      [item]: index,
    }),
    {},
  );
  return data.sort((a, b) => sortByObject[a[0]] - sortByObject[b[0]]);
};
export const GetMessageFromStatusCode = (
  statusCode: number,
  genitive: string,
  type: ApiTypes,
): string => {
  switch (statusCode) {
    case 400: {
      return `Servern kunde inte hantera ${genitive} ${type}, vänligen säkerställ att alla fält är korrekta.`;
    }
    case 401: {
      return 'Åtgärden nekades, vänligen logga in på nytt och försäkra dig om att du har rätt att utföra åtgärden';
    }
    case 200 || 202: {
      return `${capitalizeFirstLetter(genitive)} ${type} har skapats.`;
    }
    case 500: {
      return 'Internt fel, vänligen försök igen.';
    }
    default: {
      return '';
    }
  }
};
export function hashCode(str: string): number {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
export function getRandomString(): string {
  return Math.random().toString(36).slice(2, 7);
}
