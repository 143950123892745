import styled from 'styled-components';

const StyledMainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-color: #fff;
`;
const MainContainer: React.FC = ({ children }) => (
  <StyledMainContainer>{children}</StyledMainContainer>
);
export default MainContainer;
