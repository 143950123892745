import { useState } from 'react';
import * as React from 'react';
import GroceryForm from 'Features/Grocery/Components/IngredientForm/GroceryForm';
import MyRecipes from 'Features/Admin/MyRecipes/MyRecipes';
import MyMenus from 'Features/Admin/MyMenus/MyMenus';
import MyPageMenu from 'Features/Admin/MyPageMenu/MyPageMenu';
import RecipeForm from '../../Features/Recipe/Components/RecipeForm/RecipeForm';
import withAuthentication from '../../Components/HOC/withAuthentication';
import Popup from '../../Components/Popup/Popup';
import { FormType } from '../../Types/Enums';
import MyIngredients from '../../Features/Admin/MyIngredients/MyIngredients';
import { FunctionObject } from '../../Features/Interfaces/FunctionObject';

const MyPage: React.FC = () => {
  const functions: Array<FunctionObject> = [
    {
      id: 1,
      name: 'Skapa recept',
      component: 'createRecipe',
    },
    {
      id: 2,
      name: 'Skapa ingrediens',
      component: 'createIngredient',
    },
    {
      id: 3,
      name: 'Mina recept',
      component: 'myRecipes',
    },
    {
      id: 4,
      name: 'Mina ingredienser',
      component: 'myIngredients',
    },
    {
      id: 5,
      name: 'Mina veckomenyer',
      component: 'myMenus',
    },
  ];
  const [open, setOpen] = useState<boolean>(false);
  const [component, setComponent] = useState<string>('');
  const onClick = (object: FunctionObject) => {
    setOpen(true);
    setComponent(object.component);
  };
  const onPopupClose = () => setComponent('');
  const renderSwitch = () => {
    switch (component) {
      case 'createRecipe':
        return (
          <Popup isOpen={open} onPopupClose={onPopupClose}>
            <RecipeForm formType={FormType.CREATE} />
          </Popup>
        );
      case 'createIngredient':
        return (
          <Popup isOpen={open} onPopupClose={onPopupClose}>
            <GroceryForm />
          </Popup>
        );
      case 'myRecipes':
        return <MyRecipes />;
      case 'myIngredients':
        return <MyIngredients />;
      case 'myMenus':
        return <MyMenus />;
      default:
        return <></>;
    }
  };
  return (
    <div>
      <MyPageMenu onClick={onClick} functions={functions} />
      {renderSwitch()}
    </div>
  );
};
export default withAuthentication(MyPage);
