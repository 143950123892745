import * as React from 'react';
import styled from 'styled-components';
import { hashCode } from 'Utils/Utils';
import Instruction from './InstructionsListItem/Instruction';

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const Instructions: React.FC<{ instructions: string[] }> = ({
  instructions,
}) => (
  <StyledList>
    {instructions.map((x: string, i: number) => (
      <Instruction key={hashCode(x)} index={i} name={x} />
    ))}
  </StyledList>
);
export default Instructions;
