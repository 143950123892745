export enum FormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}
export enum FormErrorMessages {
  NotNumber = 'Fältet måste enbart innehålla siffror.',
  WrongPattern = 'Fältet måste ha formatet',
  ValueTooLow = 'Fältet måste ha ett värde av minst',
  EmptyValue = 'Fältet får inte vara tomt',
}
