import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './Interfaces/User';

export interface UserState {
  loading: boolean;
  currentUser?: User;
  loggedIn: boolean;
  accessToken?: string;
  keepLoggedIn: boolean;
}

const initialState: UserState = {
  loading: false,
  loggedIn: false,
  keepLoggedIn: false,
};
export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      state.loggedIn = true;
      state.loading = false;
    },
    destroy: (state) => {
      state.loggedIn = false;
      state.currentUser = undefined;
      state.accessToken = undefined;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
export const { setCurrentUser, destroy, setToken, setLoading } =
  userSlice.actions;
export default userSlice.reducer;
