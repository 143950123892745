import * as React from 'react';
import MainContainer from 'Components/Layout/MainContainer';
import styled from 'styled-components';
import CreateUser from '../../Components/CreateUser/CreateUser';

const StyledMainContainer = styled(MainContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
`;
const CreateUserPage: React.FC = () => (
  <StyledMainContainer>
    <CreateUser />
  </StyledMainContainer>
);
export default CreateUserPage;
