import { reset } from 'Features/Grocery/nutritionsSlice';
import { useAppSelector } from 'Hooks/storeHooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Select, TextInput } from 'grommet';
import inputStyles from 'Features/Forms/InputStyles';
import { AddCircle, Undo } from 'grommet-icons';
import IconStyles from 'Components/Icons/IconStyles';

import { NutritionValue } from '../../Interfaces';
import { Nutrition } from '../../../../Components/Nutrition/Interfaces';
import SearchNutrition from '../SearchNutrition/SearchNutrition';

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
  width: 100%;
`;
const StyledNutrition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
`;
const StyledName = styled(Box)`
  ${inputStyles}
  height: 3.5em;
  padding: 1rem 1.2rem;
  border: 1px solid rgba(0, 0, 0, 0.33);
`;
const StyledSpanLabel = styled.span`
  align-self: flex-start;
  margin-bottom: 0.5em;
`;

const StyledInput = styled(TextInput)`
  min-width: 7em;
`;

const StyledReset = styled(Undo)`
  ${IconStyles}
`;
const StyledAdd = styled(AddCircle)`
  ${IconStyles}
`;
interface Props {
  addNutrition: (nutrition: NutritionValue) => void;
}
interface NutritionState {
  nutrition?: Nutrition;
  amount: number;
  unit: string;
  weight: number;
}
const initialState = {
  nutrition: undefined,
  amount: 0,
  unit: 'g',
  weight: 0,
};
const NutritionValueInput: React.FC<Props> = ({ addNutrition }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<NutritionState>(initialState);
  const { nutrition, amount, unit, weight } = state;
  const { currentNutrition } = useAppSelector((appState) => appState.nutrition);
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const key = event.currentTarget.name as keyof NutritionState;
    const value = event.currentTarget.value as string;
    setState({
      ...state,
      [key]: value as unknown as Pick<NutritionState, keyof NutritionState>,
    });
  };
  const onClick = () => {
    if (nutrition) {
      const newNut = {
        nutrition,
        value: amount,
        unit,
        weight,
      };
      addNutrition(newNut);
      setState(initialState);
      dispatch(reset());
    }
  };
  useEffect(() => {
    if (currentNutrition) {
      setState((prevState) => ({
        ...prevState,
        nutrition: currentNutrition,
      }));
    }
  }, [currentNutrition]);

  const { name } = currentNutrition || {};
  return (
    <StyledSection>
      <StyledSpanLabel>Find existing nutrition: </StyledSpanLabel>
      <SearchNutrition />
      {currentNutrition && (
        <StyledNutrition>
          {name && <StyledName background='accent-4'>{name}</StyledName>}
          <StyledInput
            name='amount'
            onChange={onChange}
            value={amount}
            step='.01'
            min='0'
            type='number'
          />
          <Select
            value={unit}
            name='unit'
            options={['g', 'mg', 'µg', 'kj', 'kcal', '%']}
            onChange={onChange}
          />
          <StyledReset
            size='32px'
            type='button'
            onClick={() => dispatch(reset())}
          />

          <StyledAdd size='32px' type='button' onClick={onClick} />
        </StyledNutrition>
      )}
    </StyledSection>
  );
};
export default NutritionValueInput;
