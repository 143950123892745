import DeleteButton from 'Components/DeleteButton/DeleteButton';
import { Box } from 'grommet';
import { Edit } from 'grommet-icons';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onClick: () => void;
};

const StyledBox = styled(Box)`
   {
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    left: 20px;
  }
  @media screen and (max-width: $medium-breakpoint) {
     {
      left: auto;
      right: 10px;
      top: 5px;
    }
  }
`;

const RecipeControls: React.FC<Props> = ({ onClick }) => (
  <StyledBox direction='row' gap='1em'>
    <Edit
      size='28px'
      role='button'
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    />
    <DeleteButton />
  </StyledBox>
);
export default RecipeControls;
