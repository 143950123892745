import * as React from 'react';
import styled from 'styled-components';
import ContentSection from '../../Components/Layout/ContentSection';
import Login from '../../Components/Login/Login';

const StyledDiv = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;
const LoginPage: React.FC = () => (
  <StyledDiv>
    <ContentSection>
      <Login />
    </ContentSection>
  </StyledDiv>
);
export default LoginPage;
