import Login from 'Components/Login/Login';
import Navigation from 'Components/Navigation/Navigation';
import { Box, DropButton, Nav } from 'grommet';
import { Home, Logout, User } from 'grommet-icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { defaultTheme } from 'theme';
import { AppState } from '../../Store/store';
import { logoutUser } from '../../User/UserEffects';

interface Props {
  className?: string;
}
const StyledDropButton = styled(DropButton)`
  &&& {
    background-image: none;
    background-color: ${defaultTheme.global?.colors?.brand};
    padding: 14px 10px 10px;
    cursor: pointer;
    position: relative;
    color: #fff;
    border: none;
    &::after {
      content: '';
      position: absolute;
      height: 0.1em;
      left: 0.7em;
      right: 0.7em;
      bottom: 0;
      background: currentColor;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      transition: -webkit-transform 150ms ease-in-out;
      transition: transform 150ms ease-in-out;
      transition: transform 150ms ease-in-out;
    }
    &:hover,
    &:focus {
      background-image: none;
      &::after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
`;
const ButtonContainer = styled.div``;
const LoginButton: React.FC<Props> = ({ className }) => {
  const { currentUser } = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = async () => {
    if (currentUser) {
      dispatch(logoutUser());
    }
  };

  return (
    <ButtonContainer className={className}>
      {currentUser == null ? (
        <StyledDropButton
          a11yTitle='Login user button'
          role='button'
          label='Login'
          icon={<User />}
          tabIndex={-1}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropProps={{
            margin: {
              vertical: '0.8rem',
            },
          }}
          dropContent={
            <Box pad='medium' width='medium' background='white' color='white'>
              <Login />
            </Box>
          }
        />
      ) : (
        <StyledDropButton
          a11yTitle='Logged in user controls'
          dropAlign={{ top: 'bottom' }}
          dropProps={{
            margin: {
              vertical: '0.3em',
            },
          }}
          dropContent={
            <Box pad='medium' width='small' background='brand' color='white'>
              <Nav wrap direction='column'>
                <Navigation
                  icon={<Home />}
                  label='Account'
                  onClick={() => navigate('/account/my-account/')}
                />
                <Navigation icon={<Logout />} label='Logout' onClick={logout} />
              </Nav>
            </Box>
          }
          role='button'
          tabIndex={0}
          icon={<User />}
        />
      )}
    </ButtonContainer>
  );
};
export default LoginButton;
