/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { Button as GrommetButton, ButtonExtendedProps } from 'grommet';

interface Props extends ButtonExtendedProps {
  inverted?: boolean;
  height?: string;
  type: 'button' | 'submit' | 'reset';
}

const StyledButton = styled(GrommetButton)<{
  $inverted?: boolean;
  $height?: string;
}>`
	background-color: ${({ theme: { colors }, $inverted: inverted }) =>
    inverted ? '#fff' : colors.buttonColor};
	outline: none;
	font-size: 1.3em;
	font-weight: 600;
	letter-spacing: 0.14rem;
	height: ${({ $height }) => $height || '5rem'};
	padding: 0.8em 1.5em;
	width: auto;
	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
		opacity: 75%;
	}
}

`;
const Button: React.FC<Props> = ({
  children,
  inverted,
  type,
  className,
  disabled,
  height,
  onClick,
}) => (
  <StyledButton
    $height={height}
    $inverted={inverted}
    type={type}
    className={className}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </StyledButton>
);
export default Button;
