import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledPart = styled.section`
  width: 100%;
  max-width: 100%;
`;

interface Props {
  title?: string;
  children: ReactNode;
  part: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}
const FormPart: React.FC<Props> = ({ part, children, title }) => (
  <StyledPart key={part}>
    {title && <h3>{title}</h3>}
    {children}
  </StyledPart>
);
export default FormPart;
