import { Dispatch } from 'redux';

import { setMessage } from 'Features/Messages/messageSlice';
import { JwtToken } from './Interfaces/JwtToken';
import { AuthenticateRequest } from './Interfaces/AuthenticateRequest';
import { User } from './Interfaces/User';
import { destroy, setCurrentUser, setLoading, setToken } from './UserSlice';
import tokenApi from './TokenApi';
import userApi from './UserApi';

/**
 * Login provided user
 * @param {string} username - username of user to login
 * @param {string} password - password of user to login
 * @returns {Promise<void>} - the request promise
 */
export const loginUser =
  (username: string, password: string) =>
  (dispatch: Dispatch): void => {
    dispatch(setLoading(true));
    const request: AuthenticateRequest = {
      username,
      password,
    };
    tokenApi
      .authenticate(request)
      .then((response: JwtToken) => {
        const { accessToken } = response;
        dispatch(setToken(accessToken));
      })
      .then(() => {
        userApi.current().then((user?: User) => {
          if(!user) {
            return;
          }
          dispatch(setCurrentUser(user));
          setLoading(false);
        });
      })
      .catch((error: Error) => {
        dispatch(setMessage({ message: error.message, autoClose: true }));
        dispatch(setLoading(false));
        throw error;
      });
  };
/**
 * Logout current user
 * @returns {Promise<void>}
 */
export const logoutUser =
  () =>
  (dispatch: Dispatch): void => {
    setLoading(true);
    tokenApi
      .revoke()
      .then(() => {
        dispatch(destroy());
        setLoading(false);
      })
      .catch((error) => {
        dispatch(destroy());
        dispatch(setLoading(false));
        throw error;
      });
  };
