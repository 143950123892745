import RecipePageItem from 'Features/Recipe/Components/Recipe/Recipe';
import { Recipe } from 'Features/Recipe/types';
import { Button, Main, PageContent } from 'grommet';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import recipeApi from '../../RecipeApi';

const StyledButton = styled(Button)`
  justify-self: center;
  align-self: center;
`;
const StyledMain = styled(Main)`
  min-height: 100vh;
`;
const Inspiration: React.FC = () => {
  const [recipe, setRecipe] = useState<Recipe>();
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const onClick = async () => {
    const result = await recipeApi.random();
    if (result) {
      setRecipe(result);
      setFirstRun(false);
    }
  };
  return (
    <PageContent>
      <StyledMain direction='column' justify='center' align='center'>
        {recipe && <RecipePageItem recipe={recipe} />}
        <StyledButton
          margin={{
            bottom: '10vh',
          }}
          size='large'
          primary
          type='button'
          onClick={onClick}
          label={`${firstRun ? 'Inspire me!' : 'Another..'}`}
        />
      </StyledMain>
    </PageContent>
  );
};
export default Inspiration;
