import { BlobServiceClient } from '@azure/storage-blob';
import { useEffect, useState } from 'react';
// TODO: Fix hardcoded values
const blobServiceClient = new BlobServiceClient(
  `https://recipifyrr.blob.core.windows.net`,
);
const CONTAINER = 'images';
const containerClient = blobServiceClient.getContainerClient(CONTAINER);

type BlobState = {
  blob?: Blob;
  loading: boolean;
};

const useBlobImage = (name?: string): BlobState => {
  const [state, setState] = useState<BlobState>({ loading: true });
  useEffect(() => {
    if (!name) {
      return;
    }
    const downloadBlob = async () => {
      try {
        const blobClient = await containerClient.getBlobClient(name);
        const response = await blobClient.download();
        const blob = await response.blobBody;
        if (blob) {
          setState({
            blob,
            loading: false,
          });
        }
      } catch {
        setState({
          loading: false,
        });
      }
    };
    downloadBlob();
  }, [name]);
  return state;
};

export default useBlobImage;
