import RecipeList from 'Features/Recipe/Components/RecipeList/RecipeList';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Main, PageContent, TextInput } from 'grommet';
import { Recipe } from 'Features/Recipe/types';
import recipeApi from '../../Recipe/RecipeApi';
import { AppState } from '../../../Store/store';
import { User } from '../../../User/Interfaces/User';

const MyRecipes: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>();
  const [search, setSearch] = useState<string>('');
  const user = useSelector<AppState>((state) => state.user.currentUser) as User;
  const { id } = user;
  useEffect(() => {
    const getRecipes = async () => {
      const response = id && (await recipeApi.byUserId(id));
      if (response) {
        setRecipes(response);
      }
    };
    getRecipes();
  }, [id]);
  const searchRecipes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  return (
    <PageContent>
      <Main direction='column' gap='medium'>
        <TextInput name='search' onChange={searchRecipes} value={search} />
        {recipes && (
          <RecipeList
            showToggle
            recipes={recipes.filter((x) => x.name.includes(search))}
          />
        )}
      </Main>
    </PageContent>
  );
};
export default MyRecipes;
