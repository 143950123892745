import { AxiosRequestConfig, AxiosResponse } from 'axios';
import storeRegistry from 'Store/StoreRegistry';
import { setToken } from 'User/UserSlice';

export async function TokenRequestInterceptor(
  request: AxiosRequestConfig,
): Promise<AxiosRequestConfig> {
  // add auth header with jwt if account is logged in and request is to the api url
  const store = storeRegistry.getStore();
  const {
    user: { accessToken },
  } = store.getState() ?? { user: {} };
  if (accessToken && request.headers) {
    Object.assign(request.headers, {
      Authentication: `Bearer ${accessToken}`,
    });
  }

  return request;
}

export async function TokenResponseInterceptor(
  response: AxiosResponse<unknown>,
): Promise<AxiosResponse<unknown>> {
  // if a new token was returned, set it in the state
  if (response.status === 200 && response.headers.Authentication) {
    const token = response.headers.Authentication.split(' ')[1];
    if (token) {
      storeRegistry.getStore().dispatch(setToken(token));
    }
  }
  return response;
}
