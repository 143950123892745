import { Box, Button, Text } from 'grommet';
import * as React from 'react';

interface Props {
  onAccept: () => void;
  type: string;
}
const DeleteForm: React.FC<Props> = ({ onAccept, type }) => (
  <Box direction='column' pad='small' align='center'>
    <Text size='large' margin='medium'>{`${type} will be deleted`}</Text>
    <Box direction='row' pad='10px'>
      <Button
        size='medium'
        primary
        onClick={onAccept}
        type='button'
        label='Delete'
      />
    </Box>
  </Box>
);
export default DeleteForm;
