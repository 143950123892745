import * as React from 'react';
import styled from 'styled-components';
import { Button, TextInput } from 'grommet';
import { Search as SearchIcon } from 'grommet-icons';

import useSearch from '../Hooks/useSearch';
import useSuggestions from '../Hooks/useSuggestions';
import { SearchType } from '../types';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.small}) {
    flex-direction: column;
    gap: 1em;
  }
}

`;
const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
`;
const StyledInput = styled(TextInput)<{
  $background?: string;
  $height?: string;
}>`
&&& {
    color: #333;
    margin-right: 2rem;
    font-size: 1.5rem;
    width: 100%;
    border: none;
    -webkit-transition: 60ms ease-in-out;
    transition: 60ms ease-in-out;
    height: ${({ $height }) => $height || '5rem'};
    ${({ $background }) => $background && `background-color:${$background};`}
    background-repeat: no-repeat;
    background-position: 0.5em center;
    padding: 0.5em 1.7em;
    &:focus-within,&:focus {
      border-radius: 0px;
      border: none;
    };
      background-image: none;
      &:focus-within,&:focus {
        border: none;
        outline: none;
      }
    }
  }
}
`;

const StyledButton = styled(Button)<{ $height?: string }>`
  &&& {
    margin-left: 1rem;
    padding: 1.4em 2em;
    color: #fff;
    height: ${({ $height }) => $height || '5rem'};
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.small}) {
      width: 100%;
      margin-left: inherit;
    }
  }
`;

const StyledIcon = styled(SearchIcon)`
  margin: 0em 0.6em;
`;

interface Props {
  type: SearchType;
  results?: number;
  navigateToSearch?: boolean;
  clickToSearch?: boolean;
  showButton?: boolean;
  height?: number;
  onSelect?: (id: string) => void;
  background?: string;
}
const Search: React.FC<Props> = ({
  type,
  results = 10,
  navigateToSearch,
  clickToSearch,
  showButton,
  height,
  background,
  onSelect: externalSelect,
}) => {
  const { onChange, onSelect, suggestions, query } = useSuggestions(
    type,
    externalSelect,
    navigateToSearch,
    clickToSearch,
    results,
  );
  const { onSearch } = useSearch(type, results);
  return (
    <StyledContainer>
      <StyledSearchContainer>
        <StyledInput
          $background={background}
          icon={<StyledIcon color='black' />}
          $height={height?.toString()}
          autoComplete='off'
          name='search'
          role='searchbox'
          type='search'
          value={query}
          onChange={onChange}
          suggestions={
            (suggestions &&
              suggestions?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))) ??
            []
          }
          onSuggestionSelect={onSelect}
        />
      </StyledSearchContainer>
      {showButton && (
        <StyledButton
          primary
          type='button'
          onClick={() => onSearch(query)}
          label='Search'
        />
      )}
    </StyledContainer>
  );
};
export default Search;
