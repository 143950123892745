import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../Store/store';
import { UserState } from '../../User/UserSlice';
import HamburgerDropdown from '../HamburgerDropdown/HamburgerDropdown';

const StyledNav = styled.nav`
  justify-self: flex-end;
  align-self: flex-end;
`;
const StyledHamburger = styled.div`
  height: 30px;
  width: 40px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;
const StyledLine = styled.div<{ open: boolean }>`
  transform: rotate(0deg);
  background-color: ${({ theme }) => theme.colors.allowedColors.lightGrey};
  height: 5px;
  margin: 5px;
  border-radius: 2px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  border-radius: 4px;
  ${({ open }) =>
    open &&
    `
  &:first-child {
    transform: rotate(45deg);
  }
  &:nth-child(2) {
    transform: translateX(50px);
  }
  &:nth-child(3) {
    transform: rotate(-45deg);
  }`}
`;
const HamburgerMenu: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { currentUser } = useSelector<AppState, UserState>(
    (state) => state.user,
  );
  const onClick = () => {
    setOpen(false);
  };
  return (
    <StyledNav>
      <StyledHamburger
        onKeyDown={() => setOpen(!open)}
        tabIndex={0}
        role='button'
        onClick={() => setOpen(!open)}
      >
        <StyledLine open={open} />
        <StyledLine open={open} />
        <StyledLine open={open} />
      </StyledHamburger>
      <HamburgerDropdown
        onClick={onClick}
        open={open}
        currentUser={currentUser}
      />
    </StyledNav>
  );
};

export default HamburgerMenu;
