import useGetMessage from 'Features/Messages/useGetMessage';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import SearchPage from 'Features/Search/Components/SearchPage';
import store from 'Store/store';
import storeRegistry from 'Store/StoreRegistry';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getUnits } from 'Store/appSlice';
import { useAppSelector } from 'Hooks/storeHooks';
import { setCurrentUser } from 'User/UserSlice';
import userApi from 'User/UserApi';
import CreateUserPage from './Pages/CreateUserPage/CreateUserPage';
import InspirationPage from './Pages/InspirationPage/InspirationPage';
import LoginPage from './Pages/LoginPage/LoginPage';
import MenuPage from './Pages/MenuPage/MenuPage';
import MenusPage from './Pages/MenusPage/MenusPage';
import MyPage from './Pages/MyPage/MyPage';
import RecipePage from './Features/Recipe/Components/RecipePage/RecipePage';
import RecipesPage from './Features/Recipe/Components/RecipesPage/RecipesPage';
import StartPage from './Pages/StartPage/StartPage';

const StyledApp = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const AppWrapper: React.FC = () =>
  useRoutes([
    { path: '/', element: <StartPage /> },
    { path: '/menus', element: <MenusPage /> },
    { path: '/menus/:id', element: <MenuPage /> },
    { path: '/recipes', element: <RecipesPage /> },
    { path: '/recipes/:id', element: <RecipePage /> },
    { path: '/inspiration', element: <InspirationPage /> },
    { path: '/account/my-account', element: <MyPage /> },
    { path: '/login', element: <LoginPage /> },
    { path: 'search', element: <SearchPage /> },
    { path: '/account/create', element: <CreateUserPage /> },
  ]);
const App: React.FC = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const dispatch = useDispatch();
  storeRegistry.register(store);
  useEffect(() => {
    dispatch(getUnits());
  }, [dispatch]);
  useEffect(() => {
    if (!currentUser) {
      userApi.current().then((user) => user && dispatch(setCurrentUser(user)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const messages = useGetMessage();
  return (
    <StyledApp>
      <AppWrapper />
      {messages}
    </StyledApp>
  );
};
export default App;
