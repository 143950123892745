import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { User } from '../../User/Interfaces/User';

interface Props {
  onClick: (event: React.MouseEvent | React.TouchEvent) => void;
  open: boolean;
  currentUser?: User;
}

const StyledHamburger = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  width: ${({ open }) => (open ? '100%' : '0px')};
  max-width: 100%;
  font-family: 'Roboto';
  color: ${({ theme }) => theme.colors.textColor};
  background-color: #fff;
  list-style: none;
  left: 0;
  z-index: 5;
  top: 4.5rem;
  text-align: right;
  transition: ease-in-out 0.3s;
  margin-block-start: 0;
  margin-block-end: 0;
  height: auto;
  flex-direction: column;
  padding: 5px;
  }
`;
const StyledLink = styled(Link)`
  padding: 10px 20px;
  margin: 0px 10px;
  font-size: 20px;
  border-bottom: 1px solid grey;

  &:last-child {
    border-bottom: none;
  `;

const HamburgerDropdown: React.FC<Props> = ({ onClick, open, currentUser }) => {
  // TODO: Fix this
  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <StyledHamburger
      open={open}
      onClick={onClick}
      role='button'
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      <StyledLink to='/'>Hem</StyledLink>
      <StyledLink key='recipes' to='/recipes'>
        Recept
      </StyledLink>
      <StyledLink to='/menus'>Menus</StyledLink>
      <StyledLink key='inspiration' to='/inspiration'>
        Inspiration
      </StyledLink>
      {currentUser && (
        <StyledLink key='create' to='/account/create'>
          Create User
        </StyledLink>
      )}
      <StyledLink key='login' to='/login'>
        Login
      </StyledLink>
    </StyledHamburger>
  );
};
export default HamburgerDropdown;
