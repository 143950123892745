import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SearchResult, Suggestable } from 'Features/Search/types';
import { ApiDataResponse } from '../../Types/types';
import Api from '../../Api/Api';
import { ApiConfig } from '../../Api/ApiConfig';
import { ApiResponse } from '../../Api/Interfaces/ApiResponse';
import { CreatedGrocery, Grocery } from './Interfaces';

enum Endpoints {
  GET = '/',
  COUNT = '/count',
  UNITS = '/units',
  CREATE = '/create',
  UPDATE = '/update',
  DELETE = '/delete',
  SEARCH = '/search',
  SUGGESTIONS = '/suggestions',
}

export class GroceryApi extends Api {
  private baseUrl = 'grocery';

  constructor(config: AxiosRequestConfig) {
    super(config);
    this.byId = this.byId.bind(this);
    this.paginated = this.paginated.bind(this);
    this.count = this.count.bind(this);
  }

  /**
   * Generates an HTTP Request to get ingredients paginated
   * @returns {Promise<Grocery[]>} ingredients - Ingredients fitting the query paramters
   */
  public paginated(
    start: number,
    limit: number,
  ): Promise<{ totalCount: number; result: Grocery[] }> {
    return this.get<ApiResponse<{ item1: number; item2: Grocery[] }>>(
      `${this.baseUrl}${Endpoints.GET}?start=${start}&limit=${limit}`,
    )
      .then(
        (
          response: AxiosResponse<
            ApiResponse<{ item1: number; item2: Grocery[] }>
          >,
        ) => {
          const { data } = response;
          const { item1, item2 } = data.result;
          return { totalCount: item1, result: item2 };
        },
      )
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP request to get the specific grocery
   * @param {string} id - id of grocery to get
   * @returns {Promise<Grocery>} - the grocery
   * @memberof GroceryApi
   */
  public byId(id: string): Promise<Grocery> {
    return this.get<ApiResponse<Grocery>>(`${this.baseUrl}/${id}`)
      .then((response: AxiosResponse<ApiResponse<Grocery>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get count of all ingredients
   * @returns {Promise<number>} number - count of ingredents
   */
  public count(): Promise<number> {
    return this.get<ApiResponse<number>>(
      `${this.baseUrl}${Endpoints.COUNT}`,
      ApiConfig,
    )
      .then((response: AxiosResponse<ApiResponse<number>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get all types of units in database
   * @returns {Promise<string[]>} number - count of ingredents
   */
  public units(): Promise<string[]> {
    return this.get<
      ApiDataResponse<string[]>,
      AxiosResponse<ApiDataResponse<string[]>>
    >(`${this.baseUrl}${Endpoints.UNITS}`, ApiConfig)
      .then((response: AxiosResponse<ApiDataResponse<string[]>>) => {
        const { data } = response;
        return data.result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get a simplified version of the ingredients
   *
   * @param {string} query - String to search from
   * @returns {Promise<Suggestable[]>} an array of search suggestions
   */
  public suggestions(
    query: string,
    start: number,
    limit: number,
    abortController: AbortController,
  ): Promise<Suggestable[]> {
    return this.get<ApiDataResponse<Suggestable[]>>(
      `${this.baseUrl}${Endpoints.SUGGESTIONS}?query=${query}&start=${start}&limit=${limit}`,
      {
        ...this.config,
        signal: abortController.signal,
      },
    )
      .then((response: AxiosResponse<ApiDataResponse<Suggestable[]>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Generates an HTTP Request to get a list of ingredients
   *
   * @param {string} query - String to search from
   * @param {number} start - Start position of search result
   * @param {number} limit - Amount of ingredients to get
   * @returns {Promise<[Grocery[], number]>} an array of search suggestions
   */
  public search(
    query: string,
    start: number,
    limit: number,
    abortController: AbortController,
  ): Promise<SearchResult<Grocery>> {
    return this.get<ApiResponse<SearchResult<Grocery>>>(
      `${this.baseUrl}${Endpoints.SEARCH}/?query=${query}&start=${start}&limit=${limit}`,
      {
        ...this.config,
        signal: abortController.signal,
      },
    )
      .then((response: AxiosResponse<ApiResponse<SearchResult<Grocery>>>) => {
        const {
          data: { result },
        } = response;
        return result;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Adds a new grocery to the system
   * @param {Grocery} grocery - grocery to create
   * @returns {Promise<unknown>} - the request promise
   */
  public create(grocery: CreatedGrocery): Promise<unknown> {
    return this.post<AxiosResponse, CreatedGrocery, AxiosResponse>(
      `${this.baseUrl}${Endpoints.CREATE}`,
      grocery,
      ApiConfig,
    );
  }

  /**
   * Updates grocery in system
   * @param {Grocery} grocery - grocery to update
   * @returns {Promise} - the request promise
   */
  public update(grocery: Grocery): Promise<number> {
    return this.put(
      `${this.baseUrl}/${grocery.id}${Endpoints.UPDATE}`,
      grocery,
    );
  }

  /**
   * Deletes a grocery from the system
   * @param {string} id - id of grocery to delete
   * @returns {Promise} - the request promise
   */
  public remove(id: string): Promise<number> {
    return this.delete(`${this.baseUrl}/${id}`).then(
      (response: AxiosResponse) => {
        const { status } = response;
        return status;
      },
    );
  }

  /**
   * Delete multiple groceries from the system
   * @param {string[]} ids - the ids of groceries to delete
   * @returns {Promise} - the request promise
   */
  public removeMany(ids: string[]): Promise<number> {
    return this.post(`${this.baseUrl}${Endpoints.DELETE}`, { ids }).then(
      (response: AxiosResponse) => {
        const { status } = response;
        return status;
      },
    );
  }
}
const groceryApi = new GroceryApi(ApiConfig);
export default groceryApi;
