import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
  }
  body {
    font-family: "Source Sans Pro", "Sans-serif";
    margin: 0 auto;
    padding-top: 0px;
    background-color:${({ theme }) => theme.colors.backgroundColor};
    font-size: 14px;
  }
  
  h1,
  h2,
  h3 {
    color: ${({ theme }) => theme.colors.textColor};
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
  h1 {
    font-size: 3.2rem;
    letter-spacing: 0.22rem;
    line-height: 3.5rem;
  }
  h2 {
    font-size: 2rem;
    letter-spacing: 0.1rem;
    line-height: 1.7rem;
  }
  h3 {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  p {
    font-size: 1.3em;
    line-height: 1.5em;
    letter-spacing: 0.05em;
    color: $text-color;
    font-family: "Source Sans Pro", "Sans-serif";
    font-weight: 400;
  }
  a {
    color: ${({ theme }) => theme.colors.textColor};
    text-decoration: none;
    &:hover {
      font-weight: bolder;
    }
  }
  input,
  button {
    font-family: "Source Sans Pro", "Sans";
  }
  #root {
    margin: 0 auto;
    min-height: 100vh;
  }
 
  
  .content {
    padding: 50px;
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.medium}) {
      padding: 10px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.small}) {
    h1 {
      font-size: 2.1rem;
      letter-spacing: 0.2rem;
      line-height: 2.3rem;
      word-wrap: break-word;
    }
    h2 {
      font-size: 1.5rem;
      letter-spacing: 0.1rem;
      line-height: 1.4rem;
    }
    h3 {
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }`;
export default GlobalStyles;
