import { Main, PageContent } from 'grommet';
import styled from 'styled-components';
import GeneratedMenu from '../../Features/Menus/Components/GeneratedMenu/GeneratedMenu';

const StyledMain = styled(Main)`
  min-height: 100vh;
`;
const MenusPage: React.FC = () => (
  <PageContent align='center' justify='center'>
    <StyledMain
      pad='medium'
      direction='column'
      width='xlarge'
      background='white'
      align='center'
      justify='center'
    >
      <GeneratedMenu />
    </StyledMain>
  </PageContent>
);
export default MenusPage;
