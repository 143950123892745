import { ReactElement } from 'react';
import ProgresStep from '../ProgressBar/ProgressStep';

export default function useProgressSteps(
  max: number,
  current: number,
): { steps: ReactElement[]; amount: number } {
  const steps = [];
  for (let i = 0; i < max; i += 1) {
    steps.push(<ProgresStep key={i} active={i + 1 <= current} />);
  }
  const amount = steps.length;
  return { steps, amount };
}
