import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MessageState {
  message?: string;
  autoClose?: boolean;
}
interface Message {
  message: string;
  autoClose?: boolean;
}
const initialState: MessageState = {};

export const messageSlice = createSlice({
  initialState,
  name: 'message',
  reducers: {
    setMessage: (state, action: PayloadAction<Message>) => {
      const { message, autoClose } = action.payload;
      state.message = message;
      state.autoClose = autoClose;
    },
    clearMessage: (state) => {
      state.message = undefined;
    },
  },
});
export const { setMessage, clearMessage } = messageSlice.actions;
export default messageSlice.reducer;
