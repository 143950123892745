import { configureStore } from '@reduxjs/toolkit';
import { messageSlice } from 'Features/Messages/messageSlice';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import nutritionSlice from 'Features/Grocery/nutritionsSlice';
import grocerySlice from 'Features/Grocery/grocerySlice';
import appSlice from './appSlice';
import recipeSlice from '../Features/Recipe/recipeSlice';
import { userSlice } from '../User/UserSlice';

const middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}
export const rootReducer = combineReducers({
  app: appSlice,
  user: userSlice.reducer,
  recipe: recipeSlice,
  messages: messageSlice.reducer,
  nutrition: nutritionSlice,
  grocery: grocerySlice,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([thunkMiddleware, logger]),
});
export type AppState = ReturnType<typeof rootReducer>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export default store;
