import IconStyles from 'Components/Icons/IconStyles';
import { Close } from 'grommet-icons';
import useMaxZIndex from 'Hooks/useMaxZIndex';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  onPopupClose?: () => void;
}
const StyledPopupOverlay = styled.div<{ $zIndex: number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${({ $zIndex }) => $zIndex};
  position: fixed;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`;
const StyledPopup = styled.div<{ $isOpen: boolean }>`
  margin: auto;
  margin-top: calc(10vh - 10px);
  margin-bottom: 20px;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.colors.contentBackgroundColor};
  width: 650px;
  max-width: 100%;
  position: relative;
  z-index: 10;
  box-shadow: 0px 0px 5px 1px
    ${({ theme }) => theme.colors.allowedColors.lightGrey};
  padding: 20px 20px 25px 20px;
`;
const StyledContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;
const StyledClose = styled(Close)`
  ${IconStyles}
  position: absolute;
  right: 5px;
  top: 5px;
  align-self: flex-end;
`;
const Popup: React.FC<Props> = ({ isOpen, children, onPopupClose }) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const zIndex = useMaxZIndex();
  const close = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };
  return open ? (
    <StyledPopupOverlay $zIndex={zIndex + 1}>
      <StyledPopup $isOpen={open}>
        <StyledClose size='32px' scale={2} onClick={() => close()} />
        <StyledContent>{children}</StyledContent>
      </StyledPopup>
    </StyledPopupOverlay>
  ) : (
    <></>
  );
};
export default Popup;
