import { ThemeType } from 'grommet';

const lightTheme: Theme = {
  id: 'light',
  colors: {
    backgroundColor: '#f3f3f3',
    contentBackgroundColor: '#fff',
    highlightBackgroundColor: '#e7f7fd',
    textColor: '#111111',
    linkColor: '#8e4162ff',
    formColor: '#e7f7fd',
    buttonColor: '#8e4162ff',
    alternateButtonColor: '#bf9acaff',
    iconColor: '#41393eff',
    alertColor: '#8e4162ff',
    allowedColors: {
      columbiaBlue: '#c7e8f3ff',
      lilac: '#bf9acaff',
      twilightLavender: '#8e4162ff',
      blackCoffee: '#41393eff',
      amaranthPink: '#eda2c0ff',
      columbiaLightBlue: '#e7f7fd',
      offWhite: '#f3f3f3',
      warmblack: '#111111',
      warmblacktransparent: 'rgba(17, 17, 17, 0.25)',
      snow: '#fffafa',
      lightGrey: 'rgb(134, 136, 134)',
    },
  },
  border: '2px solid #8e4162ff',

  padding: {
    defaultPadding: '2em',
    formPadding: '1.5em 1em',
  },
  shadows: {
    boxShadow: '0px 0px 5px 1px rgba(17, 17, 17, 0.25)',
    dropShadow: 'drop-shadow(0px 5px 1px rgba(17, 17, 17, 0.25))', // for filter
  },
  selected: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.3)',
  breakPoints: {
    large: '1200px',
    medium: '996px',
    small: '768px',
    xsmall: '480px',
  },
  contentMargin: '40px',
  contentWidth: '996px',
};
const darkTheme = {
  id: 'dark',
  colors: {
    backgroundColor: '#f3f3f3',
    contentBackgroundColor: '#fff',
    highlightBackgroundColor: '#e7f7fd',
    textColor: '#333',
    linkColor: '#8e4162ff',
    formColor: '#F2C078',
    buttonColor: '#3D405B',
    alternateButtonColor: '#bf9acaff',
    iconColor: '#41393eff',
    alertColor: '#8e4162ff',
    allowedColors: {
      columbiaBlue: '#c7e8f3ff',
      lilac: '#bf9acaff',
      twilightLavender: '#8e4162ff',
      blackCoffee: '#41393eff',
      amaranthPink: '#eda2c0ff',
      columbiaLightBlue: '#e7f7fd',
      offWhite: '#f3f3f3',
      warmblack: '#111111',
      warmblacktransparent: 'rgba(17, 17, 17, 0.25)',
      snow: '#fffafa',
      lightGrey: 'rgb(134, 136, 134)',
    },
  },
  border: '2px solid #8e4162ff',

  padding: {
    defaultPadding: '2em',
    formPadding: '1.5em 1em',
  },
  shadows: {
    boxShadow: '0px 0px 5px 1px rgba(17, 17, 17, 0.25)',
    dropShadow: 'drop-shadow(0px 5px 1px rgba(17, 17, 17, 0.25))', // for filter
  },
  selected: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.3)',
  breakPoints: {
    large: '1200px',
    medium: '996px',
    small: '768px',
    xsmall: '480px',
  },
  contentMargin: '40px',
  contentWidth: '996px',
};
const defaultTheme: ThemeType = {
  global: {
    font: {
      face: 'Source Sans',
    },

    colors: {
      brand: '#3D405B',
      black: '#333',
      white: '#fff',
      'accent-1': '#E76F51', // Orange
      'accent-2': '#DB9D47', // Gold
      'accent-3': '#FFE19C', // Light yellow
      'accent-4': '#c7e8f3ff', // Blueish
      text: {
        dark: '#fff',
        light: 'black',
      },
    },
    input: {
      padding: {
        horizontal: '1rem',
        vertical: '1.2rem',
      },

      extend: () => `
      background-color: #c7e8f3ff;
      & svg {
        margin-left: 2em !important;
      }
      &:focus {
        box-shadow: 0 0 2px 2px #DB9D47;
      }
      `,
    },
  },
  menu: {
    background: 'brand',
    group: {
      container: {
        pad: 'medium',
      },
    },
    item: {
      color: {
        dark: 'white',
        light: 'black',
      },

      extend: () => `
        list-style: none;
        padding: 14px 10px 10px;
        cursor: pointer;
        position: relative;
        color: #fff;
        &::after {
          content: '';
          position: absolute;
          height: 0.1em;
          left: 0.7em;
          right: 0.7em;
          bottom: 0;
          background: currentColor;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          transition: -webkit-transform 150ms ease-in-out;
          transition: transform 150ms ease-in-out;
          transition: transform 150ms ease-in-out;
        }
        &:hover,
        &:focus {
          &::after {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }             
        `,
    },
  },
  select: {
    background: 'accent-4',
    container: {
      extend: () => `
      background: #c7e8f3ff;
      
      `,
    },
    options: {
      container: {
        background: 'accent-4',
        elevation: 'small',
      },
    },

    extend: () => `
    &&&  {
      z-index: 200;
      button {
        background-image: #c7e8f3ff;
        background-color: #c7e8f3ff;
        background: #c7e8f3ff;
        border: none;
        &:hover,
        &:focus {
        }        
      }
    }
    `,
  },
  checkBox: {
    color: 'brand',
    border: {
      color: 'brand',
      width: '3px',
    },
  },
  button: {
    transition: {
      duration: 0.75,
    },
    color: {
      light: '#fff',
      dark: '#333',
    },
    size: {
      medium: {
        pad: {
          vertical: '0.8em',
          horizontal: '1.8em',
        },
      },
      large: {
        pad: {
          vertical: '1em',
          horizontal: '3em',
        },
      },
    },

    default: {
      color: 'brand',
      background: {
        size: '200% auto',
        image: 'none',
      },
      border: {
        width: '0.2em',
        color: 'brand',
      },
      font: {
        weight: '600',
      },
      padding: {
        vertical: '0.8em',
        horizontal: '1.8em',
      },
      extend: () => `
      text-transform: uppercase;
      &:hover {
        color: #fff;        
        background-position: right center;
        background-image:  linear-gradient(to right, #3D405B  0%, #3D405B  61%, #E76F51  100%);    
      }
      `,
    },
    primary: {
      color: '#fff',
      font: {
        weight: '600',
      },
      padding: {
        vertical: '1em',
        horizontal: '2em',
      },
      extend: () => `
      &&& {
          background-image: linear-gradient(to right, #3D405B  0%, #3D405B  21%, #E76F51  100%);
          background-size: 200% auto;
          text-transform: uppercase;             
          &:hover {
            background-position: right center;
          }
        }
      `,
    },
  },
};
export interface Theme {
  id: string;
  colors: {
    backgroundColor: string;
    contentBackgroundColor: string;
    highlightBackgroundColor: string;
    textColor: string;
    linkColor: string;
    formColor: string;
    buttonColor: string;
    alternateButtonColor: string;
    alertColor: string;
    iconColor: string;
    allowedColors: {
      [key: string]: string;
    };
  };
  border: string;
  padding: {
    defaultPadding: string;
    formPadding: string;
  };
  shadows: {
    boxShadow: string;
    dropShadow: string;
  };
  selected: string;
  breakPoints: {
    large: string;
    medium: string;
    small: string;
    xsmall: string;
  };
  contentMargin: string;
  contentWidth: string;
}

export { lightTheme, darkTheme, defaultTheme };
