import IconStyles from 'Components/Icons/IconStyles';
import { Grocery } from 'Features/Grocery/Interfaces';
import { CheckBox } from 'grommet';
import { Edit, Save, Trash } from 'grommet-icons';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
  ingredient: Grocery;
  checked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIngredient?: (ingredient: Grocery) => void;
  onSave: (ingredient: Grocery) => void;
  onDelete: (id: string) => void;
}
interface State {
  weightPerMl: number;
  weightPerUnit: number;
  edit: boolean;
  edited: boolean;
  name: string;
}
const StyledListItem = styled.div`
  &:first-child {
    margin-top: 20px;
  }
  background-color: ${({ theme }) => theme.colors.contentBackgroundColor};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  margin: 5px 0;
  font-size: 1.4rem;
  padding: 0.4rem;
  &:hover {
    filter: brightness(90%);
  }
`;
const StyledCheckbox = styled(CheckBox)`
  margin-left: 0;
  align-self: center;
`;
const StyledInput = styled.input<{
  readOnly?: boolean;
  first?: boolean;
  $width?: string;
}>`
  padding: 0.4rem 0.6rem;
  border: ${({ readOnly, theme }) =>
    readOnly ? '2px solid #fff' : `2px solid ${theme.colors.formColor}`};
  width: ${({ $width }) => $width || 'auto'};
  margin: ${({ first }) =>
    first ? 'auto auto auto 0.5em' : 'auto 1em auto auto'};
  font-size: 1.1rem;

  &:focus-visible,
  &:focus-active,
  &:focus,
  &:selected {
    input {
      border: ${({ readOnly, theme }) =>
        readOnly ? '2px solid #fff' : `2px solid ${theme.colors.formColor}`};
    }
  }
`;
const StyledContainer = styled.div`
  margin: auto 0 auto auto;
  display: flex;
  flex-direction: row;
`;
const StyledIconContainers = styled.div`
  width: 5em;
  margin: auto 0 auto auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const StyledEdit = styled(Edit)`
  ${IconStyles}
`;
const StyledTrash = styled(Trash)`
  ${IconStyles}
`;
const StyledSave = styled(Save)`
  ${IconStyles}
`;
const IngredientListItem: React.FC<Props> = ({
  ingredient,
  checked,
  onCheck,
  setIngredient,
  onDelete,
  onSave,
}) => {
  const [state, setState] = useState<State>({
    weightPerMl: ingredient.weightPerMl,
    weightPerUnit: ingredient.weightPerUnit,
    edit: false,
    edited: false,
    name: ingredient.name,
  });
  const { edit, name, weightPerMl, weightPerUnit, edited } = state;
  const onClick = () => {
    if (setIngredient) {
      setState((prevState) => ({
        ...prevState,
        edit: false,
      }));
      setIngredient(ingredient);
    }
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name: fieldName } = event.currentTarget;
    setState((prevState) => ({
      ...prevState,
      edited: true,
      [fieldName]: value,
    }));
  };

  const toggleEdit = (editable: boolean) => {
    setState((prevState) => ({
      ...prevState,
      edit: editable,
    }));
  };
  const save = () => {
    onSave({ ...ingredient, name, weightPerMl, weightPerUnit });
    setState((prevState) => ({
      ...prevState,
      edited: false,
    }));
  };
  return (
    <StyledListItem>
      <StyledCheckbox
        name={ingredient.id}
        value={ingredient.id}
        checked={checked}
        onChange={onCheck}
      />
      <StyledInput
        first
        onChange={onChange}
        onFocus={() => toggleEdit(true)}
        onBlur={() => toggleEdit(false)}
        name='name'
        type='text'
        readOnly={!edit}
        $width={`${name.length + 1}em`}
        value={name}
      />
      <StyledContainer>
        <StyledInput
          onChange={onChange}
          onFocus={() => toggleEdit(true)}
          onBlur={() => toggleEdit(false)}
          step={0.1}
          name='weightPerMl'
          type='number'
          $width='4.5em'
          readOnly={!edit}
          value={weightPerMl}
        />
        <StyledInput
          onChange={onChange}
          onFocus={() => toggleEdit(true)}
          onBlur={() => toggleEdit(false)}
          step={0.1}
          name='weightPerUnit'
          type='number'
          $width='4.5em'
          readOnly={!edit}
          value={weightPerUnit}
        />
        <StyledIconContainers>
          {edited && <StyledSave size='36px' onClick={save} />}
          <StyledEdit size='36px' onClick={onClick} />
          <StyledTrash size='36px' onClick={() => onDelete(ingredient.id)} />
        </StyledIconContainers>
      </StyledContainer>
    </StyledListItem>
  );
};

export default IngredientListItem;
