import LoadingSpinner from 'Components/LoadingSpinner/LoadingSpinner';
import ShortInfo from 'Components/ShortInfo/ShortInfo';
import { Recipe } from 'Features/Recipe/types';
import { Box } from 'grommet';
import { useAppSelector } from 'Hooks/storeHooks';
import useBlobImage from 'Hooks/useBlobImage';
import useFormattedTime from 'Hooks/useFormattedTime';
import useGetRandomBackgroundImage from 'Hooks/useGetRandomBackgroundImage';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUnits } from 'Store/appSlice';
import styled from 'styled-components';
import { defaultTheme } from 'theme';
import InstructionsList from '../InstructionsList/Instructions';
import Nutritions from '../Nutritions/Nutritions';
import PortionsCounter from '../PortionsCounter/PortionsCounter';

const StyledArticle = styled.article`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-areas:
    'header img'
    'header counter'
    'content ingredients'
    'content ingredients'
    'nutritions nutritions';
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  grid-gap: 1em;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    margin: 0 10px;
    grid-template-areas:
      'header header'
      'img img'
      'counter counter'
      'ingredients ingredients'
      'content content'
      'nutritions nutritions';
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
  }
`;
const StyledHeader = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  height: 100%;
  h1 {
    margin-bottom: 0.3em;
  }
  p {
    text-align: right;
    grid-area: header;
    align-self: right;
    justify-self: flex-end;
  }
`;

const StyledImg = styled.div`
  grid-area: img;
  width: auto;
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
  height: auto;
  img {
    width: 500px;
    height: auto;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    max-height: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;
const StyledCounterContainer = styled(Box)`
  grid-area: counter;
`;
const StyledIngredientsContainer = styled(Box)`
  padding: 10px 20px;
  grid-area: ingredients;
  align-self: flex-start;
  margin-top: 2em;
  background-color: ${defaultTheme.global?.colors?.['accent-4']};
  ul {
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
    max-width: 100%;
  
    }
  }
  li {
    p {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      font-size: 1.2em;
      font-weight: 600;
    }
    margin: 0;
    padding: 0px 10px;
    font-weight: 600;
    list-style: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 98%;
    padding-right: 0;
    max-width: 98%;
    margin: 0px 5px;
`;
const StyledInstructionsContainer = styled(Box)`
  grid-area: content;
  width: 550px;
  max-width: 100%;
  padding-right: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 98%;
    padding-right: 0;
    max-width: 98%;
    margin: 0px 5px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 98%;
    padding-right: 0;
    max-width: 98%;
    margin: 0px 5px;
  }
`;
const StyledNutritions = styled(Nutritions)`
  grid-area: nutritions;
`;
type Props = {
  recipe: Recipe;
};
const RecipePageItem: React.FC<Props> = ({
  recipe: {
    name,
    id,
    description,
    cookingTime,
    ingredients: recipeIngredients,
    instructions,
    portions,
    nutritions,
  },
}) => {
  const formattedTime = useFormattedTime(cookingTime);
  const defaultImage = useGetRandomBackgroundImage();
  const { units } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const { blob, loading: blobLoading } = useBlobImage(id);
  useEffect(() => {
    if (!units) {
      dispatch(getUnits());
    }
  }, [units, dispatch]);
  const [portionsMultiplier, setPortionsMultiplier] =
    useState<number>(portions);

  const increase = async () => {
    if (portionsMultiplier < 32) {
      setPortionsMultiplier(portionsMultiplier + 1);
    }
  };
  const decrease = async () => {
    if (portionsMultiplier > 1) {
      setPortionsMultiplier(portionsMultiplier - 1);
    }
  };
  return (
    <StyledArticle>
      <StyledHeader>
        <h1>{name}</h1>
        <ShortInfo
          time={formattedTime}
          portions={portionsMultiplier}
          size={1.3}
        />
        <h3>{description}</h3>
      </StyledHeader>
      <StyledImg>
        {blobLoading ? (
          <LoadingSpinner />
        ) : (
          <img
            title={`${name} recept bild`}
            alt={`${name} recept bild`}
            src={(blob && URL.createObjectURL(blob)) || defaultImage}
          />
        )}
      </StyledImg>
      <StyledCounterContainer>
        <PortionsCounter
          increase={increase}
          decrease={decrease}
          portions={portionsMultiplier}
        />
      </StyledCounterContainer>
      <StyledIngredientsContainer>
        <ul>
          {recipeIngredients.map((x) => (
            <li>
              <p>
                {' '}
                {(x.quantity / portions) * portionsMultiplier}{' '}
                {units?.[x.unit]?.toLowerCase()} {x.grocery.name}
              </p>
            </li>
          ))}
        </ul>
      </StyledIngredientsContainer>
      <StyledInstructionsContainer>
        <h2>Gör så här:</h2>
        <InstructionsList instructions={instructions} />
      </StyledInstructionsContainer>
      <StyledNutritions nutritions={nutritions} />
    </StyledArticle>
  );
};
export default RecipePageItem;
