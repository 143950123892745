import { useEffect, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { Box, Text, TextInput } from 'grommet';
import { Trash } from 'grommet-icons';
import IconStyles from 'Components/Icons/IconStyles';

const ListItem = styled(Box)<{ $dragging: boolean; $dragOver: boolean }>`
  position: relative;
  cursor: ${({ $dragging }) => ($dragging ? 'grabbing' : 'pointer')};
  width: 100%;
  display: flex;
  align-items: center;
  &:hover {
    cursor: grab;
  }
  margin-top: ${({ $dragOver }) => ($dragOver ? '20px' : 'inherit')};
  margin-bottom: ${({ $dragOver }) => ($dragOver ? '20px' : '10px')};
  opacity: ${({ $dragOver }) => ($dragOver ? '0.5' : 'inherit')};
  opacity: ${({ $dragging }) => ($dragging ? '1.5' : 'inherit')};
  input,
  input:disabled,
  input:focus {
    margin-left: 1em;
    background-color: ${({ theme }) => theme.colors.allowedColors.columbiaBlue};
    padding: 1.5em 1em;
    border: none;
  }
`;
const StyledRemoveIcon = styled(Trash)`
  ${IconStyles};
  position: absolute;
  right: 5px;
  top: 5px;
  margin-left: 5px;
  font-size: 20px;
  justify-self: flex-end;
`;
interface Props {
  index: number;
  instruction: string;
  removeInstruction: (index: number) => void;
  updateInstruction: (index: number, instruction: string) => void;
  setDragItem: (index: number) => void;
  setDragOverItem: (index: number) => void;
  setDragDrop: (index: number) => void;
}
const InstructionsListItem: React.FC<Props> = ({
  index,
  instruction,
  removeInstruction,
  updateInstruction,
  setDragItem,
  setDragOverItem,
  setDragDrop,
}) => {
  const [value, setValue] = useState<string>(instruction);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [dragging, setDragging] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState<boolean>(false);

  useEffect(() => {
    setValue(instruction);
  }, [instruction]);
  const onClick = async () => {
    removeInstruction(index);
  };
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
    updateInstruction(index, value);
  };
  const onItemClick = async () => {
    setDisabled(!disabled);
  };
  const onBlur = async () => {
    updateInstruction(index, value);
  };
  const onDragStart = async (event: React.DragEvent<HTMLElement>) => {
    setDragging(true);
    setDragOver(false);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData(
      'image',
      event.currentTarget as unknown as string,
    );
    event.dataTransfer.setDragImage(event.currentTarget as Element, 30, 30);
    setDragItem(index);
  };
  const onDragOver = async (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    setDragOver(true);
    setDragOverItem(index);
  };
  const onDragLeave = () => {
    setDragOver(false);
  };
  const onDragEnd = async () => {
    setDragging(false);
    setDragOver(false);
    setDragDrop(index);
  };

  return (
    <li>
      <ListItem
        margin={{
          horizontal: '10px',
          vertical: '10px',
        }}
        elevation='small'
        pad='small'
        direction='row'
        background='accent-4'
        data-item={index}
        draggable
        onDrop={onDragEnd}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        $dragging={dragging}
        $dragOver={dragOver}
      >
        <Text>
          <strong>{index + 1}.</strong>
        </Text>
        <TextInput
          max='100%'
          size='small'
          onBlur={onBlur}
          onChange={onChange}
          onClick={onItemClick}
          type='text'
          value={value}
        />
        <StyledRemoveIcon size='28px' onClick={onClick} />
      </ListItem>
    </li>
  );
};
export default InstructionsListItem;
