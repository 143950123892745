import * as React from 'react';
import RecipeList from 'Features/Recipe/Components/RecipeList/RecipeList';
import { Page, PageContent } from 'grommet';
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Main,
  Text,
  Paragraph,
} from 'grommet/components';
import { Link } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import { StaticFeatures } from 'Utils/StaticContent';
import styled from 'styled-components';
import Hero from '../../Components/Hero/Hero';

import useGetPopularRecipes from '../../Hooks/useGetPopularRecipes';
import useGetRandomBackgroundImage from '../../Hooks/useGetRandomBackgroundImage';

const CardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
const StyledParagraph = styled(Paragraph)`
  max-width: 768px;
`;
const StartPage: React.FC = () => {
  const popularRecipes = useGetPopularRecipes(5);

  const backgroundImage = useGetRandomBackgroundImage();
  const navigate = useNavigate();
  return (
    <Page background='white' align='center' kind='full'>
      <PageContent>
        <Hero image={backgroundImage} />
        <Main width='full' align='center'>
          <Box justify='center' pad='large' width='full' direction='row'>
            <Box
              gap='2em'
              width='narrow'
              direction='column'
              align='center'
              background='white'
              justify='start'
              wrap
            >
              <Heading alignSelf='start' textAlign='start' level={2}>
                Welcome to Recipifyrr
              </Heading>
              <StyledParagraph>
                Are you tired of eating the same boring meals every week? Do you
                struggle to come up with new recipe ideas that are both healthy
                and delicious? Our recipe application has got you covered! Our
                app features a personalized meal plan, a recipe search engine,
                and a shopping list generator, making it easy for you to plan
                and prepare delicious meals that meet your dietary needs and
                preferences. Plus, our recipe database includes some of the most
                popular and highly rated recipes, all of which have been tested
                and approved by our team of food experts. <br />
                But don&apos;t just take our word for it - read some of the
                customer testimonials on this page to see how our recipe
                application has improved the cooking experience for people just
                like you. And be sure to check out our blog section, where we
                share tips, tricks, and recipes to help you get the most out of
                our app. Ready to take your cooking game to the next level?
                Click the button below to get started with our recipe
                application today!
              </StyledParagraph>
            </Box>
          </Box>
          <Box background='accent-3' align='center' pad='xlarge' width='full'>
            <Heading level={2}>Features</Heading>
            <CardContainer
              align='center'
              direction='row'
              justify='between'
              wrap
              responsive
              width='996px'
              gap='2em'
            >
              {StaticFeatures.map(({ header, body, link }) => (
                <Card
                  onClick={() => navigate(`/${link}`)}
                  a11yTitle={`Link to ${header}`}
                  background='white'
                  margin='small'
                  width='250px'
                  elevation='medium'
                  pad='medium'
                >
                  <CardHeader>
                    <Heading level={3}>{header}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{body}</Text>
                  </CardBody>
                  <CardFooter justify='end' alignContent='flex-end'>
                    <Link href={`/${link}`} size='medium' color='brand' />
                  </CardFooter>
                </Card>
              ))}
            </CardContainer>
          </Box>
          <Box
            direction='row'
            wrap
            justify='center'
            align='center'
            width='large'
            pad='xlarge'
          >
            {Array.isArray(popularRecipes) && (
              <RecipeList
                key='popularRecipes'
                recipes={popularRecipes}
                title='Popular recipes'
              />
            )}
          </Box>
        </Main>
      </PageContent>
    </Page>
  );
};
export default StartPage;
