import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { DragAndDropState } from '../../../Types/types';
import InstructionsItem from './InstructionsItem';

const StyledList = styled.ul`
  list-style: none;
  width: 100%;
`;
interface Props {
  instructions: string[];
  removeInstruction: (index: number) => void;
  updateInstruction: (index: number, instruction: string) => void;
  setInstructions: (instructions: string[]) => void;
}

const InstructionsList: React.FC<Props> = ({
  instructions,
  removeInstruction,
  updateInstruction,
  setInstructions,
}) => {
  const initialDragAndDropState: DragAndDropState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [] as string[],
    updatedOrder: [] as string[],
  };
  const [dragAndDrop, setDragAndDrop] = useState<DragAndDropState>(
    initialDragAndDropState,
  );
  const setDragStart = async (index: number) => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: index,
      isDragging: true,
      originalOrder: instructions,
    });
  };
  const setDragOver = async (currentIndex: number) => {
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom as number;
    const draggedTo = currentIndex;
    const itemDragged = newList[draggedFrom];

    const remainingItems = newList.filter(
      (item, index) => item && index !== draggedFrom,
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];
    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo,
      });
    }
  };

  const setDragDrop = async () => {
    setInstructions(dragAndDrop.updatedOrder as string[]);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  return (
    <StyledList>
      {instructions.map((instruction, index) => (
        <InstructionsItem
          key={instruction}
          setDragItem={setDragStart}
          setDragOverItem={setDragOver}
          setDragDrop={setDragDrop}
          index={index}
          instruction={instruction}
          removeInstruction={removeInstruction}
          updateInstruction={updateInstruction}
        />
      ))}
    </StyledList>
  );
};
export default InstructionsList;
