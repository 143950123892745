import { css } from 'styled-components';
import { defaultTheme } from 'theme';

const inputStyles = css`
  width: 100%;
  height: 3em;
  padding: ${defaultTheme.global?.input?.padding};
  color: ${defaultTheme.global?.colors?.text};
  background-color: #c7e8f3ff;
  border: none;
  font-size: 1.1rem;
  &:focus,
  &:focus-within {
    border: none;
    outline: none;
    box-shadow: 0 0 2px 2px #db9d47;
  }
`;
export default inputStyles;
