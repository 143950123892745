import { FolderOpen } from 'grommet-icons';
import * as React from 'react';
import styled from 'styled-components';
import { defaultTheme } from 'theme';

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    padding: 1.5em 1em;
    width: 100%;
    height: 3em;
    padding: ${({ theme }) => theme.padding.formPadding};
    background-color: ${defaultTheme.global?.colors?.['accent-4']};
    border: none;
    font-size: 1.1rem;
    position: relative;
    cursor: pointer;

    &:focus,
    &:focus-within {
      border: none;
      outline: none;
      box-shadow: 0 0 2px 2px #db9d47;
    }
    span {
      position: absolute;
      bottom: 13px;
    }
  }
  input {
    display: none;
  }
`;
const StyledIcon = styled(FolderOpen)`
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: auto;
  margin-right: 5px;
  &:hover {
    color: inherit;
    background-color: inherit;
    border: inherit;
  }
  //
`;
interface InputProps extends React.HTMLProps<HTMLInputElement> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  file?: File;
  placeholder?: string;
}

const FileInput: React.FC<InputProps> = ({
  onChange,
  name,
  file,
  placeholder,
  className,
  ...htmlProps
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const onClick = () => {
    if (hiddenFileInput !== null && hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <StyledInputContainer
      className={className}
      role='button'
      tabIndex={0}
      onClick={onClick}
    >
      <label htmlFor={name}>
        <span>{file ? file?.name : placeholder}</span>
        <StyledIcon />
      </label>
      <input
        ref={hiddenFileInput}
        type='file'
        onChange={onChange}
        name={name}
        accept='image/'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...htmlProps}
      />
    </StyledInputContainer>
  );
};
export default FileInput;
