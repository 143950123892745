import * as React from 'react';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import useCrop from 'Features/Forms/Hooks/useCrop';
import { Undo } from 'grommet-icons';
import { Button } from 'grommet';

const StyledPreview = styled.div`
    height: auto;
    width: 100%;
    max-width: 610px;
    margin: 10px 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}`;

const StyledButtonContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;
const StyledReset = styled(Undo)`
  font-size: 26px;
  margin: 10px;
  cursor: pointer;
  color: #fff;
  position: absolute;
  bottom: 73px;
  right: 3px;
`;

const ImageCrop: React.FC<{
  save: (image: string, title?: string) => void;
  file?: File;
}> = ({ save, file }) => {
  const { src, crop, onChange, reset, onSave, onImageLoaded } = useCrop(
    save,
    file,
  );

  return (
    <StyledPreview>
      {src ? (
        <>
          <ReactCrop
            onImageLoaded={onImageLoaded}
            src={src ?? ''}
            onChange={onChange}
            crop={crop as PixelCrop}
          />
          <StyledReset onClick={reset} />

          <StyledButtonContainer>
            <Button primary onClick={onSave} type='button' label='Save' />
          </StyledButtonContainer>
        </>
      ) : (
        <></>
      )}
    </StyledPreview>
  );
};
export default ImageCrop;
